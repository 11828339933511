import React from "react";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import {
  Grid,
  ListItemText,
  ListItemIcon,
  ListItem,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@material-ui/core/";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import PaymentIcon from "@material-ui/icons/Payment";
import GroupIcon from "@material-ui/icons/Group";
import PersonIcon from "@material-ui/icons/Person";
import ItemSidebar from "./SidebarItem";

const useStyles = makeStyles(() =>
  createStyles({
    noPadding: {
      paddingLeft: 0,
      paddingRight: 0,
    },
    noShadowBorderMargin: {
      boxShadow: "none",
      margin: "0 !important",
      " &::before ": {
        opacity: 0,
      },
    },
    noMarginAndSameHeightMin: {
      minHeight: "48px !important",
      " & > *": {
        margin: "0 !important",
      },
    },
  })
);

const SidebarDropDown = () => {
  const classes = useStyles();

  return (
    <Accordion className={classes.noShadowBorderMargin}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
        className={classes.noMarginAndSameHeightMin}
      >
        <ListItem className={classes.noPadding} button>
          <ListItemIcon>
            <PaymentIcon />
          </ListItemIcon>
          <ListItemText> Transactions </ListItemText>
        </ListItem>
      </AccordionSummary>
      <AccordionDetails className={classes.noPadding}>
        <Grid
          container
          direction="column"
          justify="flex-start"
          alignItems="flex-start"
        >
          <ItemSidebar
            linkLocate={"/transactions/Cédric"}
            linkText={"Personnel"}
            addPadding={true}
            icon={<PersonIcon />}
          />
          <ItemSidebar
            linkLocate={"/transactions/common"}
            linkText={"Commun"}
            addPadding={true}
            icon={<GroupIcon />}
          />
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

export default SidebarDropDown;
