import React from "react";
import { createStyles, Fab, makeStyles, Tooltip } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";

type OwnProps = {
  indexOfList?: number;
  addElement: (indexOfList?: number) => void;
};

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      display: "grid",
      gridTemplateColumns: "1fr",
      alignItems: "center",
      rowGap: "12px",
    },
    selfCenter: {
      justifySelf: "center",
    },
  })
);

const ButtonAddElement = (props: OwnProps) => {
  const classes = useStyles();

  return (
    <Tooltip title={"Ajouter un élément"}>
      <Fab
        type="submit"
        color="primary"
        aria-label="add"
        onClick={() => props.addElement(props.indexOfList)}
        className={classes.selfCenter}
      >
        <AddIcon />
      </Fab>
    </Tooltip>
  );
};

export default ButtonAddElement;
