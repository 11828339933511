import React from "react";
import GridContainer from "./GridContainer";
import ItemCharges from "./ItemCharges";
import SubTitle from "./SubTitle";
import useCategoriesInfoFilter from "./useCategoriesInfoFilter";
import { IAccountInfosCategories } from "../interfaces";
import roundedNumber from "./roundedNumber";

type OwnProps = {
  infosCategories: IAccountInfosCategories[];
  prorata: number;
};

const Informations = (props: OwnProps) => {
  const commonDataFiltered: IAccountInfosCategories[] = useCategoriesInfoFilter(
    props.infosCategories,
    "common"
  );

  const personnalDataFiltered: IAccountInfosCategories[] = useCategoriesInfoFilter(
    props.infosCategories,
    "personnal"
  );

  const getSpecificInformations = (
    searchName: string,
    infosCategories: IAccountInfosCategories[]
  ): IAccountInfosCategories => {
    const specificInformations = infosCategories.filter(
      (infosCategorie) => infosCategorie._id === searchName
    );

    return specificInformations[0];
  };

  const calculateAmount = (
    title: string,
    infosCategories: IAccountInfosCategories[]
  ): number => {
    let amount: number = roundedNumber(
      getSpecificInformations(title, infosCategories)?.total
    );

    if (title === "Compte commun") {
      return calculCommonChargesWithProrata(amount);
    } else if (title === "sumAccountCommonAndPerso") {
      const amountCommon = calculateAmount("Compte commun", infosCategories);
      const amountPerso = calculateAmount("Compte perso", infosCategories);

      return roundedNumber(amountCommon + amountPerso);
    } else {
      return amount;
    }
  };

  const renderItemCharges = (
    title: string,
    infosCategories: IAccountInfosCategories[]
  ): JSX.Element => {
    const isSum = title.includes("sum");
    const amount: number = calculateAmount(title, infosCategories);

    return (
      <ItemCharges
        key={title}
        variantTitle={isSum ? "button" : "body2"}
        title={isSum ? "TOTAL" : `${title} :`}
        amount={amount}
      />
    );
  };

  const calculCommonChargesWithProrata = (rawAmount: number): number =>
    roundedNumber(rawAmount * (1 - props.prorata));
  return (
    <>
      <SubTitle
        isCenterTitle={true}
        mainTitle="Entrées/Sorties"
        displayFirstDivider={true}
        displaySecondDivider={true}
        displayAvatar={false}
      />

      <GridContainer>
        <>
          {["Entrées", "Sorties", "sumDebitsAndCredits"].map((title) =>
            renderItemCharges(title, props.infosCategories)
          )}
        </>
      </GridContainer>

      <SubTitle
        isCenterTitle={true}
        mainTitle="Frais communs"
        displayFirstDivider={true}
        displaySecondDivider={true}
        displayAvatar={false}
      />

      <GridContainer>
        <>
          {commonDataFiltered.map((item) =>
            renderItemCharges(item._id, commonDataFiltered)
          )}
        </>
      </GridContainer>

      <SubTitle
        isCenterTitle={false}
        mainTitle="Au prorata:"
        displayFirstDivider={false}
        displaySecondDivider={false}
        displayAvatar={false}
        optionalAmount={calculCommonChargesWithProrata(
          getSpecificInformations("sumCommon", commonDataFiltered)?.total
        )}
      />

      <SubTitle
        isCenterTitle={true}
        mainTitle="Frais Personnels"
        displayFirstDivider={true}
        displaySecondDivider={true}
        displayAvatar={false}
      />

      <GridContainer>
        <>
          {personnalDataFiltered.map((item) =>
            renderItemCharges(item._id, personnalDataFiltered)
          )}
        </>
      </GridContainer>

      <SubTitle
        isCenterTitle={true}
        mainTitle="Carburant + Nourriture + Perso"
        displayFirstDivider={true}
        displaySecondDivider={true}
        displayAvatar={false}
      />

      <GridContainer>
        <>
          {[
            "Compte perso",
            "Compte commun",
            "sumAccountCommonAndPerso",
          ].map((title) => renderItemCharges(title, props.infosCategories))}
        </>
      </GridContainer>
    </>
  );
};

export default Informations;
