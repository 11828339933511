import { createStyles, makeStyles, Theme, Typography } from "@material-ui/core";
import React, { Fragment } from "react";
import GridContainer from "./GridContainer";
import AmountColored from "./AmountColored";
import SubTitle from "./SubTitle";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    bank: {
      display: "grid",
      gridAutoFlow: "column",
      justifyContent: "start",
      columnGap: "10px",
      alignItems: "center",
    },
    marginTopBottom: {
      marginBottom: theme.spacing(1),
      marginTop: theme.spacing(1),
    },
    hidden: {
      display: "none !important",
    },
  })
);

type rowType = {
  amount?: number;
  title: string;
};

type avatarType = {
  title: string;
  srcLogo: string;
  altLogo: string;
};

type OwnProps = {
  [index: string]: avatarType | rowType | undefined;
  avatar: avatarType;
  firstRow?: rowType;
  secondRow?: rowType;
  thirdRow?: rowType;
  fourthRow?: rowType;
  fifthRow?: rowType;
  sixthRow?: rowType;
};

const ItemBankOrCommon = (props: OwnProps) => {
  const classes = useStyles();

  const isHidden = (row: rowType | undefined): string => {
    return row !== undefined ? "" : classes.hidden;
  };

  const renderOptionalRow = (row: rowType | undefined, keyValue: string) => {
    return (
      <Fragment key={keyValue}>
        <Typography className={isHidden(row)} variant="body2">
          {row?.title}
        </Typography>
        <AmountColored
          amount={row?.amount ?? 0}
          isHidden={row ? false : true}
        />
      </Fragment>
    );
  };

  return (
    <>
      <SubTitle
        mainTitle={props.avatar.title}
        isCenterTitle={true}
        displayAvatar={true}
        displayFirstDivider={true}
        displaySecondDivider={false}
        optionalAvatar={props.avatar}
      />

      <GridContainer>
        <>
          {[
            "firstRow",
            "secondRow",
            "thirdRow",
            "fourthRow",
            "fifthRow",
            "sixthRow",
          ].map((whichRow: string) =>
            renderOptionalRow(props[whichRow], whichRow)
          )}
        </>
      </GridContainer>
    </>
  );
};

export default ItemBankOrCommon;
