import React, { useState } from "react";
import { Button, createStyles, makeStyles, TextField } from "@material-ui/core";
import CardItems from "../Account/CardItems";
import { IDropDownList } from "../interfaces";
import ButtonAddElement from "./ButtonAddElement";
import { UPDATE_DROPDOWN_LIST } from "../queries/dropDowLists";
import { useMutation } from "@apollo/client";
import DisplayAlert from "../Transactions/DisplayAlert";

type State = {
  severity: "error" | "success" | "info";
  msg: string;
};

type OwnProps = {
  dropDownLists: IDropDownList;
  indexOfDropDownList: number;
  handleChangeList: (
    value: string,
    indexOfMap: number,
    indexOfDropDownList: number
  ) => void;

  addElement: (indexOfDropDownList?: number) => void;
};

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      display: "grid",
      gridTemplateColumns: "1fr",
      alignItems: "center",
      rowGap: "12px",
    },
    selfCenter: {
      justifySelf: "center",
    },
  })
);

const CardItemsAdmin = (props: OwnProps) => {
  const classes = useStyles();

  const [updateDropDownList] = useMutation<IDropDownList>(UPDATE_DROPDOWN_LIST);
  const [afterSubmit, setAfterSubmit] = useState<State>({
    severity: "success",
    msg: "",
  });

  const translate = (text: string): string => {
    switch (text) {
      case "kindOfAmountList":
        return "Types de dépense";

      case "paymentMethodList":
        return "Moyens de paiement";

      default:
        return "";
    }
  };

  const afterSave = (kindOfList: string) => {
    // Reset message
    setAfterSubmit({
      severity: "error",
      msg: "",
    });

    const dropDownListsFilteredAndSorted = props.dropDownLists.list.filter(
      (x) => x !== "Autres" && x !== ""
    );

    dropDownListsFilteredAndSorted.sort((a, b) => a.localeCompare(b));

    const newDropDownLists = dropDownListsFilteredAndSorted.reduce<string[]>(
      (acc, curr, index) => {
        if (index === 0) acc.push("");
        acc.push(curr);
        if (index === dropDownListsFilteredAndSorted.length - 1)
          acc.push("Autres");

        return acc;
      },
      []
    );

    updateDropDownList({
      variables: {
        list: newDropDownLists,
        name: kindOfList,
      },
    })
      .then(() => {
        setAfterSubmit({
          severity: "success",
          msg: "Mise à jour effectuée",
        });
      })
      .catch(() => {
        setAfterSubmit({
          severity: "error",
          msg: "Un problème est survenu, réessayer plus tard",
        });
      });
  };

  return (
    <CardItems titleCard={translate(props.dropDownLists?.name)}>
      <>
        <div className={classes.container}>
          {props.dropDownLists &&
            props.dropDownLists.list.map((item, indexOfMap) => {
              // Masque par défaut les 2 valeurs "" && "Autres" obligatoires
              if ((item === "" && indexOfMap === 0) || item === "Autres")
                return "";
              return (
                <TextField
                  type={"text"}
                  variant={"outlined"}
                  label={`Entrez la valeur:`}
                  value={item}
                  key={indexOfMap}
                  onChange={(event) =>
                    props.handleChangeList(
                      event.target.value,
                      indexOfMap,
                      props.indexOfDropDownList
                    )
                  }
                />
              );
            })}

          <ButtonAddElement
            addElement={props.addElement}
            indexOfList={props.indexOfDropDownList}
          />

          <Button
            variant="contained"
            color="primary"
            onClick={() => afterSave(props.dropDownLists?.name)}
          >
            Sauvegarder
          </Button>

          <DisplayAlert
            severity={afterSubmit.severity}
            message={afterSubmit.msg}
            horizontal="left"
          />
        </div>
      </>
    </CardItems>
  );
};

export default CardItemsAdmin;
