import React from "react";
import useRowTitles from "./useRowTitles";
import { useGetSpecificInfos } from "./useGetSpecificInfos";
import { IAccountProps } from "../interfaces";
import ItemBankOrCommon from "./ItemBankOrCommon";
import roundedNumber from "./roundedNumber";
import { Typography } from "@material-ui/core";

const Bank = (props: IAccountProps) => {
  const rowTitles = useRowTitles(props.dateForQuery);

  const SG = useGetSpecificInfos("SG", props.infos);
  const FloaBank = useGetSpecificInfos("CDiscount", props.infos);
  const Fortuneo = useGetSpecificInfos("Fortuneo", props.infos);
  const TradeRepublic = useGetSpecificInfos("TradeRepublic", props.infos)

  const displayTitle = (isTitleSet: string | undefined): string =>
    isTitleSet ? isTitleSet : "";

  const shouldWithdrawn = (): boolean => {
    const day = new Date(Date.now()).getDate();
    if (day >= 20 && props.withdrawnFortuneoIsDone === false) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <>
      <ItemBankOrCommon
        avatar={{
          title: "Société Générale",
          srcLogo: "/images/sg.png",
          altLogo: "SG logo",
        }}
        firstRow={{
          amount: roundedNumber(SG?.amountPending),
          title: displayTitle(rowTitles?.titleOfAmountPending),
        }}
      />
      <ItemBankOrCommon
        avatar={{
          title: "Trade Republic",
          srcLogo: "/images/TradeRepublic.png",
          altLogo: "Trade Republic",
        }}
        firstRow={{
          amount: roundedNumber(TradeRepublic?.amountRealisedMonth1),
          title: displayTitle(rowTitles?.titleOfAmountInProgress),
        }}
        secondRow={{
          amount: roundedNumber(TradeRepublic?.amountPending),
          title: displayTitle(rowTitles?.titleOfAmountPending),
        }}
      />
      <ItemBankOrCommon
        avatar={{
          title: "FloaBank",
          srcLogo: "/images/cdiscount.png",
          altLogo: "Floabank logo",
        }}
        firstRow={{
          amount: roundedNumber(FloaBank?.amountRealisedMonth1),
          title: displayTitle(rowTitles?.titleOfAmountRealisedMonth1),
        }}
        secondRow={{
          amount: roundedNumber(FloaBank?.amountRealisedMonth2),
          title: displayTitle(rowTitles?.titleOfAmountRealisedMonth2),
        }}
        thirdRow={{
          amount: roundedNumber(FloaBank?.amountPending),
          title: displayTitle(rowTitles?.titleOfAmountPending),
        }}
      />
      <ItemBankOrCommon
        avatar={{
          title: "Fortuneo",
          srcLogo: "/images/fortuneo.png",
          altLogo: "Fortuneo logo",
        }}
        firstRow={{
          amount: roundedNumber(Fortuneo?.amountRealisedMonth1),
          title: `${shouldWithdrawn() ? "*" : ""} ${displayTitle(
            rowTitles?.titleOfAmountRealisedMonth1
          )} `,
        }}
        secondRow={{
          amount: roundedNumber(Fortuneo?.amountRealisedMonth2),
          title: displayTitle(rowTitles?.titleOfAmountRealisedMonth2),
        }}
        thirdRow={{
          amount: roundedNumber(Fortuneo?.amountPending),
          title: displayTitle(rowTitles?.titleOfAmountPending),
        }}
      />
      {shouldWithdrawn() && (
        <Typography variant="caption" color="secondary">
          {`*Attention, pensez à faire un virement de ${-roundedNumber(
            Fortuneo?.amountRealisedMonth1
          )}€ sur ce compte`}
        </Typography>
      )}
    </>
  );
};

export default Bank;
