import React, { useEffect, useState } from "react";
import {
  Button,
  createStyles,
  Grid,
  makeStyles,
  Theme,
} from "@material-ui/core";
import { useLazyQuery, useMutation } from "@apollo/client";
import HeaderAndSidebar from "../Theme/HeaderAndSidebar";
import TitlePage from "../Theme/TitlePage";
import Bank from "./Bank";
import {
  IAccountInfos,
  IDarkModeProps,
  IAccountInfosDetails,
  IAccountInfosCategories,
  IProrataOrAccountHistoricData,
  IWithdrawnFortuneoIsDoneData,
  IAccountChargesQuery,
} from "../interfaces";
import CardItems from "./CardItems";
import Charges from "./Charges";
import Informations from "./Informations";
import Common from "./Common";
import {
  GET_ACCOUNT_INFORMATIONS,
  GET_ACCOUNT_INFORMATION_DETAILS,
  GET_ACCOUNT_INFORMATION_CATEGORIES,
  GET_WITHDRAWN_FORTUNEO_IS_DONE,
  GET_ACCOUNT_CHARGES,
  UPDATE_PRORATA_OR_ACCOUNT_HISTORIC,
} from "../queries/account";
import useDateForQuery from "../Transactions/useDateForQuery";
import DisplayDate from "../Transactions/DisplayDate";
import { GET_PRORATA_OR_ACCOUNT_HISTORICS } from "../queries/prorataOrAccount";
import DisplayAlert from "../Transactions/DisplayAlert";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      marginBottom: theme.spacing(2),
      marginTop: theme.spacing(2),
    },
    buttonCentered: {
      textAlign: "center",
    },
  })
);

interface IAccountInfosQuery {
  getAccountInformations: IAccountInfos[];
}
interface IAccountInfosDetailsQuery {
  getAccountInformationDetails: IAccountInfosDetails[];
}

interface IAccountInfosCategoriesQuery {
  getAccountCategories: IAccountInfosCategories[];
}

type State = {
  severity: "error" | "success" | "info";
  msg: string;
};

const Accounts = (props: IDarkModeProps) => {
  const classes = useStyles();
  const [toolbarDate, setToolbarDate] = useState<Date>(new Date());
  const [balanceEndOfMonth, setBalanceEndOfMonth] = useState<number>(0);
  const dateForQuery = useDateForQuery(toolbarDate);
  const [afterSubmit, setAfterSubmit] = useState<State>({
    severity: "success",
    msg: "",
  });

  const [getInformations, { data: infos }] = useLazyQuery<IAccountInfosQuery>(
    GET_ACCOUNT_INFORMATIONS,
    {
      variables: {
        start: dateForQuery?.start,
        end: dateForQuery?.end,
        whoPaid: "Cédric",
      },
      fetchPolicy: "cache-and-network",
    }
  );

  const [getCharges, { data: charges }] = useLazyQuery<IAccountChargesQuery>(
    GET_ACCOUNT_CHARGES,
    {
      variables: {
        start: dateForQuery?.start,
        end: dateForQuery?.end,
        whoPaid: "Cédric",
      },
      fetchPolicy: "cache-and-network",
    }
  );

  const [
    getInformationsDetails,
    { data: infosDetails },
  ] = useLazyQuery<IAccountInfosDetailsQuery>(GET_ACCOUNT_INFORMATION_DETAILS, {
    variables: {
      start: dateForQuery?.start,
      end: dateForQuery?.end,
    },
    fetchPolicy: "cache-and-network",
  });

  const [
    getInformationCategories,
    { data: infosCategories },
  ] = useLazyQuery<IAccountInfosCategoriesQuery>(
    GET_ACCOUNT_INFORMATION_CATEGORIES,
    {
      variables: {
        start: dateForQuery?.start,
        end: dateForQuery?.end,
      },
      fetchPolicy: "cache-and-network",
    }
  );

  const [
    getProrataOrAccount,
    { data: prorataOrAccount },
  ] = useLazyQuery<IProrataOrAccountHistoricData>(
    GET_PRORATA_OR_ACCOUNT_HISTORICS,
    {
      variables: {
        dateOfProrataOrAccount: dateForQuery?.start,
      },
      fetchPolicy: "cache-and-network",
    }
  );

  const [
    getProrataOrAccountForNextMonth,
    { data: prorataOrAccountForNextMonth },
  ] = useLazyQuery<IProrataOrAccountHistoricData>(
    GET_PRORATA_OR_ACCOUNT_HISTORICS,
    {
      variables: {
        dateOfProrataOrAccount: dateForQuery?.end,
      },
      fetchPolicy: "cache-and-network",
    }
  );

  const [
    updateProrataOrAccountHistoric,
  ] = useMutation<IProrataOrAccountHistoricData>(
    UPDATE_PRORATA_OR_ACCOUNT_HISTORIC
  );

  const [
    getWithdrawnFortuneoIsDone,
    { data: withdrawnFortuneoIsDoneData },
  ] = useLazyQuery<IWithdrawnFortuneoIsDoneData>(
    GET_WITHDRAWN_FORTUNEO_IS_DONE,
    {
      variables: {
        start: dateForQuery?.start,
        end: dateForQuery?.end,
      },
    }
  );

  useEffect(() => {
    if (dateForQuery !== undefined) {
      getInformations();
      getInformationsDetails();
      getInformationCategories();
      getProrataOrAccount();
      getProrataOrAccountForNextMonth();
      getWithdrawnFortuneoIsDone();
      getCharges();
    }
  }, [
    dateForQuery,
    getInformations,
    getInformationsDetails,
    getInformationCategories,
    getProrataOrAccount,
    getProrataOrAccountForNextMonth,
    getWithdrawnFortuneoIsDone,
    getCharges,
  ]);

  const withdrawnFortuneoIsDone = (): boolean => {
    if (
      withdrawnFortuneoIsDoneData?.getWithdrawnFortuneoIsDone !== undefined &&
      withdrawnFortuneoIsDoneData?.getWithdrawnFortuneoIsDone !== null
    ) {
      return true;
    } else {
      return false;
    }
  };

  const displayDateForQuery = (isDateSet: Date | undefined): Date =>
    isDateSet ? isDateSet : new Date();

  const computeNextMonthBalance = () => {
    // Reset message
    setAfterSubmit({
      severity: "error",
      msg: "",
    });

    let prorataValue = 0.5;
    if (
      prorataOrAccountForNextMonth?.getProrataOrAccountHistorics.length === 0 &&
      prorataOrAccount?.getProrataOrAccountHistorics[0].prorataValue
    ) {
      prorataValue =
        prorataOrAccount?.getProrataOrAccountHistorics[0].prorataValue;
    } else if (
      prorataOrAccountForNextMonth?.getProrataOrAccountHistorics &&
      prorataOrAccountForNextMonth?.getProrataOrAccountHistorics.length > 0 &&
      prorataOrAccountForNextMonth.getProrataOrAccountHistorics[0].prorataValue
    ) {
      prorataValue =
        prorataOrAccountForNextMonth?.getProrataOrAccountHistorics[0]
          .prorataValue ?? 0.5;
    }

    updateProrataOrAccountHistoric({
      variables: {
        accountValue: balanceEndOfMonth,
        dateOfProrataOrAccount: dateForQuery?.end ?? "",
        prorataValue,
      },
    })
      .then(() => {
        setAfterSubmit({
          severity: "success",
          msg: "Calcul du mois prochain effectué",
        });
      })
      .catch(() => {
        setAfterSubmit({
          severity: "error",
          msg: "Un problème est survenu, réessayer plus tard",
        });
      });
  };

  return (
    <>
      <HeaderAndSidebar {...props}>
        <>
          <TitlePage title={"Gestion des comptes"} />
          <DisplayDate
            toolbarDate={toolbarDate}
            setToolbarDate={setToolbarDate}
          />

          <Grid
            container
            direction="row"
            justify="space-evenly"
            spacing={3}
            className={classes.container}
          >
            <CardItems titleCard="Comptes bancaires">
              <Bank
                infos={infos?.getAccountInformations ?? []}
                dateForQuery={displayDateForQuery(dateForQuery?.start)}
                amountOnAccount={
                  prorataOrAccount?.getProrataOrAccountHistorics[0]
                    ?.accountValue ?? 0
                }
                withdrawnFortuneoIsDone={withdrawnFortuneoIsDone()}
              />
            </CardItems>
            <CardItems titleCard="Résumé des dépenses">
              <Charges
                charges={charges?.getAccountCharges ?? []}
                amountOnAccount={
                  prorataOrAccount?.getProrataOrAccountHistorics[0]
                    ?.accountValue ?? 0
                }
                withdrawnFortuneoIsDone={withdrawnFortuneoIsDone()}
                toolbarDate={toolbarDate}
                balanceEndOfMonth={balanceEndOfMonth}
                setBalanceEndOfMonth={setBalanceEndOfMonth}
              />
            </CardItems>
            <CardItems titleCard="Informations">
              <Informations
                infosCategories={infosCategories?.getAccountCategories ?? []}
                prorata={
                  prorataOrAccount?.getProrataOrAccountHistorics[0]
                    ?.prorataValue ?? 0
                }
              />
            </CardItems>
            <CardItems titleCard="Commun">
              <Common
                infosDetails={infosDetails?.getAccountInformationDetails ?? []}
                prorata={
                  prorataOrAccount?.getProrataOrAccountHistorics[0]
                    ?.prorataValue ?? 0
                }
              />
            </CardItems>
          </Grid>
          <div className={classes.buttonCentered}>
            <Button
              variant="contained"
              color="primary"
              onClick={computeNextMonthBalance}
              style={{ margin: "2rem" }}
            >
              Calculer le solde du mois prochain
            </Button>
            <DisplayAlert
              severity={afterSubmit.severity}
              message={afterSubmit.msg}
            />
          </div>
        </>
      </HeaderAndSidebar>
    </>
  );
};

export default Accounts;
