import { gql } from "@apollo/client";

export const GET_STATISTICS = gql`
  query GetStatistics($start: String!, $end: String!) {
    getStatistics(start: $start, end: $end) {
      _id
      total
      byPaymentPerso
      byPaymentCommon
      byCategoriesCommon
      byCategoriesPerso
      byDebitPerso
      byDebitCommon
      byCreditPerso
      byCreditCommon
      numberTransactions
      averageProrata
    }
  }
`;
