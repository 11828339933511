import { InputAdornment, TextField, Typography } from "@material-ui/core";
import React from "react";

type OwnProps = {
  who: string;
  wage: number;
  indexOfHistoric: number;
  handleChangeWage: (value: number, indexOfHistoric: number) => void;
};

const CardItemsWages = (props: OwnProps) => {
  return (
    <>
      <Typography variant="button">{`Salaire ${props.who}:`}</Typography>

      <TextField
        type={"number"}
        value={props.wage}
        onChange={(e) =>
          props.handleChangeWage(
            parseInt(e.target.value),
            props.indexOfHistoric
          )
        }
        InputProps={{
          startAdornment: <InputAdornment position="start">€</InputAdornment>,
        }}
        id={"wage"}
        name={"MargauxWage"}
      />
    </>
  );
};

export default CardItemsWages;
