import React, { useEffect, useCallback } from "react";
import { useGetSpecificInfos } from "./useGetSpecificInfos";
import GridContainer from "./GridContainer";
import ItemCharges from "./ItemCharges";
import SubTitle from "./SubTitle";
import { IAccountInfos } from "../interfaces";
import roundedNumber from "./roundedNumber";
import { Typography } from "@material-ui/core";

interface IOwnProps {
  charges: IAccountInfos[];
  withdrawnFortuneoIsDone: boolean;
  toolbarDate: Date;
  amountOnAccount: number;
  balanceEndOfMonth: number;
  setBalanceEndOfMonth: React.Dispatch<React.SetStateAction<number>>;
}

const Charges = (props: IOwnProps) => {
  const SG = useGetSpecificInfos("SG", props.charges);
  const FloaBank = useGetSpecificInfos("CDiscount", props.charges);
  const Fortuneo = useGetSpecificInfos("Fortuneo", props.charges);
  const TradeRepublic = useGetSpecificInfos("TradeRepublic", props.charges);
  const { setBalanceEndOfMonth } = props;

  const isTheSameMonth = useCallback((): boolean => {
    const month = new Date(Date.now()).getMonth();
    const year = new Date(Date.now()).getFullYear();

    const monthToolbar = new Date(props.toolbarDate).getMonth();
    const yearToolbar = new Date(props.toolbarDate).getFullYear();

    if (month === monthToolbar && year === yearToolbar) {
      return true;
    } else {
      return false;
    }
  }, [props.toolbarDate]);

  const isBeforeTheFifthOftheMonth = useCallback((): boolean => {
    const isTheSameMonthValue = isTheSameMonth();
    const dayOfMonth = new Date(Date.now()).getDate();

    if (isTheSameMonthValue && dayOfMonth <= 5) {
      return true;
    } else {
      return false;
    }
  }, [isTheSameMonth]);

  const amountFortuneoAccordingToWithdrawn = useCallback(
    (inverted: boolean): number => {
      const amount = roundedNumber(Fortuneo?.amountRealisedMonth1);
      if (props.withdrawnFortuneoIsDone) {
        return inverted ? amount : 0;
      } else {
        return inverted ? 0 : amount;
      }
    },
    [Fortuneo?.amountRealisedMonth1, props.withdrawnFortuneoIsDone]
  );

  const actuallyOnAccountBank = useCallback((): number => {
    // La carte CDiscount est prélévé tous les 5 du mois
    // Si on est avant le 5 du mois alors on ajoute amountDebited qui est en attente de prélèvement
    const amountDebitedFloaBank = isBeforeTheFifthOftheMonth()
      ? roundedNumber(FloaBank?.amountRealisedMonth0)
      : 0;

    return roundedNumber(props.amountOnAccount - amountDebitedFloaBank);
  }, [
    FloaBank?.amountRealisedMonth0,
    isBeforeTheFifthOftheMonth,
    props.amountOnAccount,
  ]);

  const stayOnAccount = useCallback(
    (isToday: boolean): number => {
      const amountOnAccount = actuallyOnAccountBank();
      const amountFortuneoOnThisMonthInverted = amountFortuneoAccordingToWithdrawn(
        true
      );

      let sum = 0;
      const sgAmountMonth = roundedNumber(SG?.amountMonth);
      const TRAmountMonth = roundedNumber(TradeRepublic?.amountMonth);
      const oldCBs = roundedNumber(
        FloaBank?.amountCBMonth0 + Fortuneo?.amountCBMonth0
      );
      const actualCBs = roundedNumber(
        FloaBank?.amountMonth + Fortuneo?.amountMonth
      );
      const sgAmountPending = roundedNumber(SG?.amountPending);
      const TRAmountPending = roundedNumber(TradeRepublic?.amountPending);

      if (isToday) {
        sum = roundedNumber(
          amountOnAccount +
            sgAmountMonth -
            oldCBs +
            amountFortuneoOnThisMonthInverted
        );
      } else {
        sum = roundedNumber(
          amountOnAccount + sgAmountMonth + actualCBs + sgAmountPending + TRAmountMonth + TRAmountPending
        );
      }

      return sum;
    },
    [
      FloaBank?.amountCBMonth0,
      FloaBank?.amountMonth,
      Fortuneo?.amountCBMonth0,
      Fortuneo?.amountMonth,
      SG?.amountMonth,
      SG?.amountPending,
      TradeRepublic?.amountMonth,
      TradeRepublic?.amountPending,
      actuallyOnAccountBank,
      amountFortuneoAccordingToWithdrawn,
    ]
  );

  useEffect(() => {
    const balanceEndOfMonth = stayOnAccount(false);
    setBalanceEndOfMonth(balanceEndOfMonth);
  }, [setBalanceEndOfMonth, stayOnAccount]);

  const getTheFirstDayOfToolBarDate = (): string => {
    const month = new Date(props.toolbarDate).getMonth() + 1;
    const year = new Date(props.toolbarDate).getFullYear();
    let monthText = month.toString();

    if (month < 10) {
      monthText = `0${month}`;
    }

    return `01/${monthText}/${year}`;
  };

  return (
    <>
      <SubTitle
        isCenterTitle={false}
        mainTitle={`Au ${getTheFirstDayOfToolBarDate()}`}
        optionalAmount={actuallyOnAccountBank()}
        displayFirstDivider={false}
        displaySecondDivider={true}
        displayAvatar={false}
      />
      {isTheSameMonth() && (
        <GridContainer>
          <>
            {isBeforeTheFifthOftheMonth() && (
              <ItemCharges
                variantTitle="body2"
                title="Prélèvement FloaBank en cours:"
                amount={roundedNumber(FloaBank?.amountRealisedMonth0)}
              />
            )}

            {props.withdrawnFortuneoIsDone && (
              <ItemCharges
                variantTitle="body2"
                title="* Virement vers Fortuneo:"
                amount={roundedNumber(Fortuneo?.amountRealisedMonth1)}
              />
            )}

            <ItemCharges
              variantTitle="body2"
              title="* CBs du mois précédent:"
              amount={
                -roundedNumber(
                  FloaBank?.amountCBMonth0 + Fortuneo?.amountCBMonth0
                )
              }
            />

            <ItemCharges
              variantTitle="body2"
              title="Compte SG:"
              amount={roundedNumber(SG?.amountMonth)}
            />
          </>
        </GridContainer>
      )}
      {isTheSameMonth() && (
        <SubTitle
          isCenterTitle={false}
          mainTitle="Aujourd'hui"
          optionalAmount={stayOnAccount(true)}
          displayFirstDivider={true}
          displaySecondDivider={true}
          displayAvatar={false}
        />
      )}

      <GridContainer>
        <>
          <ItemCharges
            variantTitle="body2"
            title="CB CDiscount:"
            amount={roundedNumber(FloaBank?.amountMonth)}
          />
          <ItemCharges
            variantTitle="body2"
            title="CB Fortuneo:"
            amount={roundedNumber(Fortuneo?.amountMonth)}
          />
          <ItemCharges
            variantTitle="body2"
            title="Trade Republic:"
            amount={roundedNumber(TradeRepublic?.amountPending ?? 0 + TradeRepublic?.amountMonth ?? 0)}
          />
          <ItemCharges
            variantTitle="body2"
            title="SG (attente):"
            amount={roundedNumber(SG?.amountPending)}
          />
          {!isTheSameMonth() && (
            <ItemCharges
              variantTitle="body2"
              title="Compte SG:"
              amount={roundedNumber(SG?.amountMonth)}
            />
          )}
        </>
      </GridContainer>
      <SubTitle
        isCenterTitle={false}
        mainTitle="A la fin du mois"
        optionalAmount={props.balanceEndOfMonth}
        displayFirstDivider={true}
        displaySecondDivider={false}
        displayAvatar={false}
      />
      {isTheSameMonth() && (
        <Typography variant="caption" align="center" display="block">
          * Sert uniquement dans <br />
          le calcul du solde du compte à l'instant t
        </Typography>
      )}
    </>
  );
};

export default Charges;
