import { InMemoryCache, Reference } from "@apollo/client";

export const cache: InMemoryCache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        getTransactions: {
          merge(existing, incoming) {
            let transactions: Reference[] = [];
            transactions = [...incoming];

            return transactions;
          },
        },
      },
    },
  },
});
