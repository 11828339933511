import React, { useState, useEffect, useCallback } from "react";
import {
  TextField,
  createStyles,
  makeStyles,
  Typography,
  Theme,
  Button,
  Grid,
  Divider,
} from "@material-ui/core";
import { useLazyQuery } from "@apollo/client";
import HeaderAndSidebar from "../Theme/HeaderAndSidebar";
import TitlePage from "../Theme/TitlePage";
import { IDarkModeProps, IStatisticsQuery } from "../interfaces";
import { GET_STATISTICS } from "../queries/statistics";
import roundedNumber from "../Account/roundedNumber";
import HorizontalBarStacked from "./HorizontalBarStacked";
import Pie from "./Pie";

type State = {
  dateStatStart: string;
  dateStatEnd: string;
};

type StateOfHorizontalBarStacked = {
  positives: Array<number>;
  negatives: Array<number>;
  labels: Array<string>;
};

type StateOfPie = {
  transactions: Array<number>;
  labels: Array<string>;
};

type StateAmount = {
  personnalHorizontalBarStacked: StateOfHorizontalBarStacked;
  commonPieChart: StateOfPie;
  personnalPieChart: StateOfPie;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    containerRoot: {
      textAlign: "center",
      " & > *": {
        marginBottom: theme.spacing(3),
      },
    },
    containerDates: {
      display: "grid",
      gridTemplateColumns: "1fr 1fr",
      justifyItems: "center",
    },
  })
);

const Statistics = (props: IDarkModeProps) => {
  const classes = useStyles();
  const [{ dateStatStart, dateStatEnd }, setDateState] = useState<State>({
    dateStatStart: "",
    dateStatEnd: "",
  });

  const [
    { personnalHorizontalBarStacked, commonPieChart, personnalPieChart },
    setState,
  ] = useState<StateAmount>({
    personnalHorizontalBarStacked: {
      positives: [],
      negatives: [],
      labels: [],
    },
    personnalPieChart: {
      labels: [],
      transactions: [],
    },
    commonPieChart: {
      labels: [],
      transactions: [],
    },
  });

  const [getStatistics, { data: statistics }] = useLazyQuery<IStatisticsQuery>(
    GET_STATISTICS,
    { fetchPolicy: "cache-and-network" }
  );

  const handleChangeDate = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const { id, value } = e.target;
    setDateState((x) => ({ ...x, [id]: value }));
  };

  const onClick = () => {
    if (dateStatStart !== "" && dateStatEnd !== "") {
      getStatistics({ variables: { start: dateStatStart, end: dateStatEnd } });
    }
  };

  const getSpecificValue = useCallback(
    (type: string): number | undefined => {
      if (type === "prorata" || type === "prorataPercent") {
        let prorata =
          statistics?.getStatistics.filter((x) => x._id === "averageProrata")[0]
            .averageProrata ?? 0.5;
        prorata = 1 - prorata;

        if (type === "prorata") {
          return roundedNumber(prorata);
        } else {
          return roundedNumber(prorata) * 100;
        }
      } else {
        return (
          statistics?.getStatistics.filter(
            (x) => x._id === "numberTransactions"
          )[0]?.numberTransactions ?? 0
        );
      }
    },
    [statistics?.getStatistics]
  );

  const computeAmount = useCallback(() => {
    const prorata = roundedNumber(getSpecificValue("prorata"));
    return (
      statistics?.getStatistics
        .slice()
        .sort((a, b) => a._id.localeCompare(b._id))
        .reduce<StateAmount>(
          (acc, value) => {
            if (value.byCreditPerso || value.byDebitPerso) {
              const positive = roundedNumber(value.byCreditPerso);
              const negative = roundedNumber(value.byDebitPerso);

              acc.personnalHorizontalBarStacked.labels.push(
                value._id?.toString() ?? ""
              );
              acc.personnalHorizontalBarStacked.positives.push(positive);
              acc.personnalHorizontalBarStacked.negatives.push(negative);

              if (value.total < 0) {
                acc.personnalPieChart.labels.push(value._id?.toString() ?? "");
                acc.personnalPieChart.transactions.push(
                  roundedNumber(value.total)
                );
              }
            }

            if (
              (value.byCreditCommon || value.byDebitCommon) &&
              value.total < 0
            ) {
              const transactions = roundedNumber(value.total * prorata);

              acc.commonPieChart.labels.push(value._id?.toString() ?? "");
              acc.commonPieChart.transactions.push(transactions);
            }

            return acc;
          },
          {
            personnalHorizontalBarStacked: {
              positives: [],
              negatives: [],
              labels: [],
            },
            personnalPieChart: {
              labels: [],
              transactions: [],
            },
            commonPieChart: {
              labels: [],
              transactions: [],
            },
          }
        ) ?? {
        personnalHorizontalBarStacked: {
          positives: [],
          negatives: [],
          labels: [],
        },
        personnalPieChart: {
          labels: [],
          transactions: [],
        },
        commonPieChart: {
          labels: [],
          transactions: [],
        },
      }
    );
  }, [statistics?.getStatistics, getSpecificValue]);

  useEffect(() => {
    if (statistics?.getStatistics) {
      const newStateAmount = computeAmount();

      setState({
        personnalHorizontalBarStacked: {
          positives: [
            ...newStateAmount.personnalHorizontalBarStacked.positives,
          ],
          negatives: [
            ...newStateAmount.personnalHorizontalBarStacked.negatives,
          ],
          labels: [...newStateAmount.personnalHorizontalBarStacked.labels],
        },
        personnalPieChart: {
          labels: [...newStateAmount.personnalPieChart.labels],
          transactions: [...newStateAmount.personnalPieChart.transactions],
        },
        commonPieChart: {
          labels: [...newStateAmount.commonPieChart.labels],
          transactions: [...newStateAmount.commonPieChart.transactions],
        },
      });
    }
  }, [computeAmount, statistics?.getStatistics]);

  const renderMessage = () => {
    if (statistics) {
      return `Les graphiques ont été générés à partir de ${getSpecificValue(
        "numberTransaction(s)"
      )} transactions.
      Le prorata moyen sur cette période est de ${roundedNumber(
        getSpecificValue("prorataPercent")
      )}%`;
    } else {
      return "";
    }
  };

  return (
    <>
      <HeaderAndSidebar {...props}>
        <div className={classes.containerRoot}>
          <TitlePage title={"Statistiques"} />
          <div>
            <Typography variant="subtitle1">
              Choisir la période / intervalle pour la génération des
              statistiques:
            </Typography>
          </div>
          <>
            <div className={classes.containerDates}>
              <Typography variant="body1">De :</Typography>
              <Typography variant="body1">À :</Typography>
              <TextField
                type="date"
                value={dateStatStart}
                onChange={handleChangeDate}
                id={"dateStatStart"}
              />
              <TextField
                type="date"
                value={dateStatEnd}
                onChange={handleChangeDate}
                id={"dateStatEnd"}
              />
            </div>
            <Button
              variant="contained"
              color="primary"
              onClick={() => onClick()}
            >
              Générer le rapport
            </Button>
          </>
          <>
            {statistics && (
              <>
                <Typography
                  variant="body1"
                  color="secondary"
                  style={{ whiteSpace: "pre-line" }}
                >
                  {renderMessage()}
                </Typography>
                <Grid container justify="center" spacing={0}>
                  <Grid item xs={4}>
                    <Divider variant="middle" />
                    <Typography variant="h6">Personnel</Typography>
                    <Divider variant="middle" />
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid item md={12} lg={8}>
                    <HorizontalBarStacked
                      ArrayOfLabels={personnalHorizontalBarStacked.labels}
                      ArrayOfDataPositives={
                        personnalHorizontalBarStacked.positives
                      }
                      ArrayOfDataNegatives={
                        personnalHorizontalBarStacked.negatives
                      }
                      chartTitle="Montant des transactions par catégorie, sur le compte personnel"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} lg={4} xl={3}>
                    <Pie
                      ArrayOfLabels={personnalPieChart.labels}
                      ArrayOfTransactions={personnalPieChart.transactions}
                      chartTitle="Répartition des dépenses par catégorie, sur le compte personnel"
                    />
                  </Grid>
                </Grid>
                <Grid container justify="center" spacing={0}>
                  <Grid item xs={4}>
                    <Divider variant="middle" />
                    <Typography variant="h6">Commun</Typography>
                    <Divider variant="middle" />
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6} lg={4} xl={3}>
                    <Pie
                      ArrayOfLabels={commonPieChart.labels}
                      ArrayOfTransactions={commonPieChart.transactions}
                      chartTitle="Répartition des dépenses par catégorie, sur le compte commun"
                    />
                  </Grid>
                </Grid>
              </>
            )}
          </>
        </div>
      </HeaderAndSidebar>
    </>
  );
};

export default Statistics;
