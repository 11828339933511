import React from "react";
import clsx from "clsx";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { IPropsHeaderAndSidebar } from "../interfaces";

import Header, { drawerWidth } from "./Header";
import Sidebar from "./Sidebar";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
    },
    drawerHeader: {
      display: "flex",
      alignItems: "center",
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
      justifyContent: "flex-end",
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      marginLeft: -drawerWidth,
    },
    contentShift: {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    },
  })
);

const HeaderAndSidebar = (props: IPropsHeaderAndSidebar) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Header {...props} />
      <Sidebar {...props} />

      <main
        className={clsx(classes.content, {
          [classes.contentShift]: props.drawerState,
        })}
      >
        <div className={classes.drawerHeader} />

        {props.children}
      </main>
    </div>
  );
};

export default HeaderAndSidebar;
