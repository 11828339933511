import React from "react";
import {
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Checkbox,
} from "@material-ui/core/";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { useParams } from "react-router-dom";
import { IData, IParamsAccount } from "../interfaces";

type Order = "asc" | "desc";

interface HeadCell {
  id: keyof IData;
  label: string;
}

interface IOwnProps {
  numSelected: number;
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof IData
  ) => void;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    visuallyHidden: {
      border: 0,
      clip: "rect(0 0 0 0)",
      height: 1,
      margin: -1,
      overflow: "hidden",
      padding: 0,
      position: "absolute",
      top: 20,
      width: 1,
    },

    notRenderingBreakPoints: {
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
    },
    notRenderingCommon: {
      display: "none",
    },
  })
);

const TableHeader = (props: IOwnProps) => {
  const classes = useStyles();
  const { typeAccount } = useParams<IParamsAccount>();

  const headCells: HeadCell[] = [
    { id: "dateOfAmount", label: "Date" },
    { id: "name", label: "Libellé" },
    { id: "amount", label: "Montant" },
    {
      id: typeAccount === "common" ? "whoPaid" : "paymentMethod",
      label: typeAccount === "common" ? "Payeur" : "Moyen de paiement",
    },
    {
      id: "kindOfAmount",
      label: "Type de dépense",
    },
    {
      id: typeAccount === "common" ? "isProrata" : "isCommon",
      label: typeAccount === "common" ? "Prorata" : "Commun",
    },
    {
      id: "isRealised",
      label: "Effectué",
    },
  ];

  const createSortHandler = (property: keyof IData) => (
    event: React.MouseEvent<unknown>
  ): void => {
    props.onRequestSort(event, property);
  };

  const isDisplayHeader = (index: number): string => {
    if (index >= 3 && (index < 6 || typeAccount !== "common")) {
      return classes.notRenderingBreakPoints;
    } else if (index >= 6 && typeAccount === "common") {
      return classes.notRenderingCommon;
    }

    return "";
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={
              props.numSelected > 0 && props.numSelected < props.rowCount
            }
            checked={props.rowCount > 0 && props.numSelected === props.rowCount}
            onChange={props.onSelectAllClick}
            inputProps={{ "aria-label": "selectionne toutes les dépenses" }}
          />
        </TableCell>
        {headCells.map((headCell: HeadCell, index: number) => (
          <TableCell
            key={headCell.id}
            align="left"
            padding="default"
            sortDirection={props.orderBy === headCell.id ? props.order : false}
            className={isDisplayHeader(index)}
          >
            <TableSortLabel
              active={props.orderBy === headCell.id}
              direction={props.orderBy === headCell.id ? props.order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {props.orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {props.order === "desc"
                    ? "trie descendant"
                    : "trie ascendant"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export default TableHeader;
