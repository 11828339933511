import React from "react";
import HeaderAndSidebar from "../Theme/HeaderAndSidebar";
import { IDarkModeProps } from "../interfaces";
import TitlePage from "../Theme/TitlePage";

const Dashboard = (props: IDarkModeProps) => {
  return (
    <>
      <HeaderAndSidebar {...props}>
        <TitlePage title={"Tableau de bord"} />
      </HeaderAndSidebar>
    </>
  );
};

export default Dashboard;
