import { gql } from "@apollo/client";

export const GET_ACCOUNT_INFORMATIONS = gql`
  query GetAccountInformations(
    $start: String!
    $end: String!
    $whoPaid: String!
  ) {
    getAccountInformations(start: $start, end: $end, whoPaid: $whoPaid) {
      _id
      amountDebited
      amountRealisedMonth0
      amountRealisedMonth1
      amountRealisedMonth2
      amountPending
    }
  }
`;

export const GET_ACCOUNT_INFORMATION_DETAILS = gql`
  query GetAccountInformationDetails($start: String!, $end: String!) {
    getAccountInformationDetails(start: $start, end: $end) {
      _id
      paidWithProrata
      paidWithoutProrata
      withdrawn
      switch
    }
  }
`;

export const GET_ACCOUNT_INFORMATION_CATEGORIES = gql`
  query GetAccountCategories($start: String!, $end: String!) {
    getAccountCategories(start: $start, end: $end) {
      _id
      total
    }
  }
`;

export const GET_WITHDRAWN_FORTUNEO_IS_DONE = gql`
  query GetWithdrawnFortuneoIsDone($start: String!, $end: String!) {
    getWithdrawnFortuneoIsDone(start: $start, end: $end) {
      _id
    }
  }
`;

export const GET_ACCOUNT_CHARGES = gql`
  query GetAccountCharges($start: String!, $end: String!, $whoPaid: String!) {
    getAccountCharges(start: $start, end: $end, whoPaid: $whoPaid) {
      _id
      amountMonth
      amountCBMonth0
      amountRealisedMonth0
      amountRealisedMonth1
      amountPending
    }
  }
`;

export const UPDATE_PRORATA_OR_ACCOUNT_HISTORIC = gql`
  mutation UpdateProrataOrAccountHistoric(
    $dateOfProrataOrAccount: String!
    $accountValue: Float!
    $prorataValue: Float!
  ) {
    updateProrataOrAccountHistoric(
      dateOfProrataOrAccount: $dateOfProrataOrAccount
      prorataValue: $prorataValue
      accountValue: $accountValue
    ) {
      n
      nModified
    }
  }
`;
