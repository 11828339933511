import {
  createStyles,
  Divider,
  makeStyles,
  Typography,
  Theme,
  Avatar,
} from "@material-ui/core";
import React from "react";
import AmountColored from "./AmountColored";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "grid",
      gridAutoFlow: "column",
      justifyContent: (props: OwnProps) =>
        props.isCenterTitle ? "center" : "space-between",
      columnGap: "10px",
      alignItems: "center",
    },
    marginTopBottom: {
      marginBottom: theme.spacing(1),
      marginTop: theme.spacing(1),
    },
    hidden: {
      display: "none",
    },
  })
);

type avatarType = {
  srcLogo: string;
  altLogo: string;
};

type OwnProps = {
  mainTitle: string;
  optionalTitle?: string;
  optionalAmount?: number;
  optionalAvatar?: avatarType;
  isCenterTitle: boolean;
  displayFirstDivider: boolean;
  displaySecondDivider: boolean;
  displayAvatar: boolean;
};

const SubTitle = (props: OwnProps) => {
  const classes = useStyles(props);

  const isHidden = (displayDivider: boolean): string => {
    if (displayDivider) return classes.marginTopBottom;

    return classes.hidden;
  };

  const renderAmountColoredOrOptionalTitle = (): JSX.Element => {
    if (props.optionalAmount !== undefined)
      return <AmountColored amount={props.optionalAmount} isHidden={false} />;

    return <Typography variant="button">{props.optionalTitle}</Typography>;
  };
  return (
    <>
      <Divider
        className={isHidden(props.displayFirstDivider)}
        variant="middle"
      />
      <div className={classes.container}>
        <Avatar
          alt={props.optionalAvatar?.altLogo}
          src={props.optionalAvatar?.srcLogo}
          className={isHidden(props.displayAvatar)}
        />
        <Typography variant="button">{props.mainTitle}</Typography>
        {renderAmountColoredOrOptionalTitle()}
      </div>
      <Divider
        className={isHidden(props.displaySecondDivider)}
        variant="middle"
      />
    </>
  );
};

export default SubTitle;
