import React, { Fragment, useCallback, useEffect, useState } from "react";
import ItemBankOrCommon from "./ItemBankOrCommon";
import SubTitle from "./SubTitle";
import { IAccountInfosDetails } from "../interfaces";
import roundedNumber from "./roundedNumber";

type OwnProps = {
  infosDetails: IAccountInfosDetails[];
  prorata: number;
};

interface userInfoType extends IAccountInfosDetails {
  leftToPaid?: number;
  part?: number;
}

const Common = (props: OwnProps) => {
  const initUserState: userInfoType = {
    _id: "",
    paidWithProrata: 0,
    paidWithoutProrata: 0,
    withdrawn: 0,
    part: 0,
    leftToPaid: 0,
    switch: 0,
  };

  const [userInfos, setUserInfos] = useState<userInfoType[]>([
    initUserState,
    initUserState,
  ]);

  const calculPart = useCallback(
    (userName: string): number => {
      const sumProrata = props.infosDetails.reduce(
        (acc, curr) => acc + roundedNumber(curr.paidWithProrata),
        0
      );
      const partWithoutProrata = props.infosDetails.reduce(
        (acc, curr) => acc + roundedNumber(curr.paidWithoutProrata) / 0.5,
        0
      );

      return userName === "Margaux"
        ? roundedNumber(sumProrata * props.prorata + partWithoutProrata)
        : roundedNumber(sumProrata * (1 - props.prorata) + partWithoutProrata);
    },
    [props]
  );

  const shouldFetchUserInfos = useCallback(
    (userName: string): userInfoType => {
      const userBackendInfos: IAccountInfosDetails[] = props.infosDetails.filter(
        (item) => item._id === userName
      );

      return userBackendInfos[0];
    },
    [props.infosDetails]
  );

  const getAndCalculateUserInfos = useCallback((): userInfoType[] => {
    const userBackendInfos = [
      shouldFetchUserInfos("Margaux"),
      shouldFetchUserInfos("Cédric"),
    ];
    const userParts = [calculPart("Margaux"), calculPart("Cédric")];

    const leftToPaid: number =
      roundedNumber(userParts[0]) -
      (roundedNumber(userBackendInfos[0]?.paidWithProrata) +
        roundedNumber(userBackendInfos[0]?.withdrawn)) +
      roundedNumber(userBackendInfos[1]?.withdrawn) +
      roundedNumber(userBackendInfos[1]?.switch);

    const switchValue: number = userBackendInfos[1].switch;

    const userInfos: userInfoType[] = userBackendInfos.map(
      (item: IAccountInfosDetails, index: number) => {
        return {
          ...item,
          part: userParts[index],
          leftToPaid: index === 0 ? leftToPaid : -leftToPaid,
          switch: index === 0 ? switchValue : -switchValue,
        };
      }
    );

    return userInfos;
  }, [calculPart, shouldFetchUserInfos]);

  // Pour améliorer l'affichage (couleur et signe), le paiement sera vert et positif (Ex: provenant de la query --> -120€ transformer en 120€)
  const calculPaid = (indexOfUserInArray: number): number =>
    (roundedNumber(userInfos[indexOfUserInArray].paidWithProrata) +
      roundedNumber(userInfos[indexOfUserInArray].paidWithoutProrata)) *
    -1;

  const renderItemBankOrCommon = (nameOfUser: string): JSX.Element => {
    const indexOfUserInArray = nameOfUser === "Margaux" ? 0 : 1;
    const invertedIndexOfUserInArray = nameOfUser === "Margaux" ? 1 : 0;
    return (
      <Fragment key={indexOfUserInArray}>
        <ItemBankOrCommon
          avatar={{
            title: nameOfUser,
            srcLogo: `/images/${nameOfUser}.png`,
            altLogo: `Avatar ${nameOfUser}`,
          }}
          firstRow={{
            amount: calculPaid(indexOfUserInArray),
            title: "Payé:",
          }}
          secondRow={{
            amount: roundedNumber(userInfos[indexOfUserInArray].part),
            title: "Part:",
          }}
          thirdRow={{
            amount: roundedNumber(userInfos[indexOfUserInArray].switch),
            title: "Autres dépenses:",
          }}
          fourthRow={{
            amount: roundedNumber(userInfos[indexOfUserInArray].withdrawn),
            title: "Virement transmis:",
          }}
          fifthRow={{
            amount: roundedNumber(
              -userInfos[invertedIndexOfUserInArray].withdrawn
            ),
            title: "Virement reçu:",
          }}
          sixthRow={{
            amount: roundedNumber(userInfos[indexOfUserInArray].leftToPaid),
            title: "Reste",
          }}
        />
      </Fragment>
    );
  };

  useEffect(() => {
    if (props.infosDetails.length > 0) setUserInfos(getAndCalculateUserInfos());
  }, [getAndCalculateUserInfos, props.infosDetails.length]);

  return (
    <>
      <SubTitle
        isCenterTitle={true}
        mainTitle="Prorata:"
        optionalTitle={`${props.prorata * 100}%`}
        displayFirstDivider={true}
        displaySecondDivider={false}
        displayAvatar={false}
      />
      {["Margaux", "Cédric"].map((indexOfUserInArray) =>
        renderItemBankOrCommon(indexOfUserInArray)
      )}
    </>
  );
};

export default Common;
