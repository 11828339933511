import React, { useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import {
  IDarkModeProps,
  IDropDownList,
  IDropDownListsData,
} from "../interfaces";
import { GET_DROPDOWN_LIST } from "../queries/dropDowLists";
import HeaderAndSidebar from "../Theme/HeaderAndSidebar";
import TitlePage from "../Theme/TitlePage";
import CardItemsAdmin from "./CardItemsAdmin";
import { createStyles, Grid, makeStyles, Theme } from "@material-ui/core";
import ManagementProrataOrAccount from "./ManagementProrataOrAccount";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      marginBottom: theme.spacing(2),
      marginTop: theme.spacing(2),
    },
  })
);

const Administration = (props: IDarkModeProps) => {
  const classes = useStyles();

  const { data: dropDownLists } = useQuery<IDropDownListsData>(
    GET_DROPDOWN_LIST,
    {
      variables: { name: ["kindOfAmountList", "paymentMethodList"] },
    }
  );
  const [dropDownListsState, setDropDownListsState] = useState<IDropDownList[]>(
    []
  );

  useEffect(() => {
    if (dropDownLists?.getDropDownLists)
      setDropDownListsState(dropDownLists.getDropDownLists);
  }, [setDropDownListsState, dropDownLists]);

  const addElement = (indexOfDropDownList: number = 0): void => {
    const newDropDownList: IDropDownList[] = JSON.parse(
      JSON.stringify(dropDownListsState)
    );

    newDropDownList[indexOfDropDownList].list.push("");
    setDropDownListsState(newDropDownList);
  };

  const handleChangeList = (
    value: string,
    indexOfMap: number,
    indexOfDropDownList: number
  ): void => {
    const newDropDownList: IDropDownList[] = JSON.parse(
      JSON.stringify(dropDownListsState)
    );
    newDropDownList[indexOfDropDownList].list[indexOfMap] = value;
    setDropDownListsState(newDropDownList);
  };

  return (
    <HeaderAndSidebar {...props}>
      <>
        <TitlePage title={"Administration"} />
        <Grid
          container
          direction="row"
          justify="space-evenly"
          spacing={3}
          className={classes.container}
        >
          {dropDownListsState.map((item, index) => (
            <Grid item md={3} key={item.name + index}>
              <CardItemsAdmin
                dropDownLists={item}
                indexOfDropDownList={index}
                handleChangeList={handleChangeList}
                addElement={addElement}
              />
            </Grid>
          ))}
          <Grid item md={6} xl={4}>
            <ManagementProrataOrAccount />
          </Grid>
        </Grid>
      </>
    </HeaderAndSidebar>
  );
};

export default Administration;
