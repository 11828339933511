import React from "react";
import {
  createStyles,
  FormControl,
  FormHelperText,
  InputAdornment,
  makeStyles,
  TextField,
} from "@material-ui/core";

interface IInputText {
  label: string;
  type: string;
  isLabelShrink: boolean;
  register: any;
  name: string;
  error: string;
}

const useStyles = makeStyles(() =>
  createStyles({
    centered: {
      margin: "auto",
    },
  })
);

const InputText = (props: IInputText) => {
  const classes = useStyles();

  const isError: boolean = props.error !== "" ? true : false;

  const formating = (value: any): string | number => {
    if (props.type === "number") return parseFloat(value);

    return value;
  };

  const numberIsCorrect = (value: any): boolean => {
    if (props.type === "number" && value === 0) return false;

    return true;
  };

  return (
    <FormControl error={isError}>
      <TextField
        error={isError}
        label={props.label}
        type={props.type}
        variant="outlined"
        InputLabelProps={props.isLabelShrink ? { shrink: true } : {}}
        inputProps={props.type === "number" ? { step: 0.01 } : {}}
        InputProps={
          props.type === "number"
            ? {
                startAdornment: (
                  <InputAdornment position="start">€</InputAdornment>
                ),
              }
            : {}
        }
        id={props.name}
        name={props.name}
        inputRef={props.register({
          setValueAs: (value: any) => formating(value),
          validate: (value: any) =>
            numberIsCorrect(value) || "Doit être différent de 0!",
          required: "Champ incomplet",
        })}
      />

      <FormHelperText className={classes.centered} hidden={!isError}>
        {props.error}
      </FormHelperText>
    </FormControl>
  );
};

export default InputText;
