import { useCallback, useEffect, useState } from "react";

type rowTitlesType = {
  titleOfAmountRealisedMonth1: string;
  titleOfAmountRealisedMonth2: string;
  titleOfAmountPending: string;
  titleOfAmountInProgress: string;
};

const useRowTitles = (dateForQuery: Date) => {
  const [rowTitles, setRowTitles] = useState<rowTitlesType>();

  const addMonth = useCallback(
    (howManyMonth: number): Date => {
      const newDate = new Date(dateForQuery);
      newDate.setMonth(newDate.getMonth() + howManyMonth);

      return newDate;
    },
    [dateForQuery]
  );

  const setTitleOfAmountRealisedMonths = useCallback(
    (whichMonth: number): string => {
      const month = addMonth(whichMonth);
      const monthToString: string = transformDateToDateString(month);

      return `Prélevé en ${monthToString}:`;
    },
    [addMonth]
  );

  const getRowTitles = useCallback(() => {
    const titleOfAmountPending: string = "En attente:";
    const titleOfAmountInProgress: string = "En cours:"

    const titleOfAmountRealisedMonth1 = setTitleOfAmountRealisedMonths(1);
    const titleOfAmountRealisedMonth2 = setTitleOfAmountRealisedMonths(2);

    setRowTitles({
      titleOfAmountRealisedMonth1,
      titleOfAmountRealisedMonth2,
      titleOfAmountPending,
      titleOfAmountInProgress,
    });
  }, [setTitleOfAmountRealisedMonths]);

  useEffect(() => {
    getRowTitles();
  }, [dateForQuery, getRowTitles]);

  const transformDateToDateString = (date: Date): string => {
    return date.toLocaleDateString("fr-FR", {
      year: "numeric",
      month: "long",
    });
  };

  return rowTitles;
};

export default useRowTitles;
