import React, { Dispatch, SetStateAction } from "react";
import { Grid, IconButton, Tooltip, Typography } from "@material-ui/core";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";

type IFormatDate = {
  year: "numeric" | "2-digit" | undefined;
  month: "numeric" | "2-digit" | "long" | undefined;
};

type OwnProps = {
  toolbarDate: Date;
  setToolbarDate: Dispatch<SetStateAction<Date>>;
  setPage?: Dispatch<SetStateAction<number>>;
};

const DisplayDate = (props: OwnProps) => {
  const handleChangeToolbarDate = (addOrRemoveMonth: string): void => {
    let newDate: Date = new Date(props.toolbarDate);
    addOrRemoveMonth === "add"
      ? newDate.setMonth(newDate.getMonth() + 1)
      : newDate.setMonth(newDate.getMonth() - 1);

    props.setToolbarDate(newDate);
  };

  // Formater les dates avec un format spécial (Ex: décembre 2020)
  const formatDate = (date: Date): string => {
    const options: IFormatDate = {
      year: "numeric",
      month: "long",
    };
    return new Intl.DateTimeFormat("fr-FR", options).format(date);
  };

  return (
    <Grid container direction="row" justify="space-between" alignItems="center">
      <Tooltip title="Voir le mois précédent" placement="left" arrow>
        <IconButton
          aria-label="mois précédent"
          onClick={() => handleChangeToolbarDate("substract")}
        >
          <KeyboardArrowLeftIcon />
        </IconButton>
      </Tooltip>

      <Typography variant="h6" id="tableTitle" component="div">
        {formatDate(props.toolbarDate)}
      </Typography>
      <Tooltip title="Voir le mois suivant" placement="right" arrow>
        <IconButton
          aria-label="mois suivant"
          onClick={() => handleChangeToolbarDate("add")}
        >
          <KeyboardArrowRightIcon />
        </IconButton>
      </Tooltip>
    </Grid>
  );
};

export default DisplayDate;
