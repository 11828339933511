import { gql } from "@apollo/client";

export const GET_DROPDOWN_LIST = gql`
  query getDropDownLists($name: [String!]) {
    getDropDownLists(name: $name) {
      list
      name
    }
  }
`;

export const UPDATE_DROPDOWN_LIST = gql`
  mutation UpdateList($name: String!, $list: [String!]) {
    updateList(name: $name, list: $list) {
      _id
    }
  }
`;
