import { IAccountInfos } from "../interfaces";

export const useGetSpecificInfos = (
  filter: string,
  datas: IAccountInfos[]
): IAccountInfos => {
  const specificInfos: IAccountInfos[] = datas.filter(
    (item) => item._id === filter
  );

  return specificInfos[0];
};
