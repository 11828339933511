import React, { Dispatch, SetStateAction } from "react";
import clsx from "clsx";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import {
  Toolbar,
  Typography,
  IconButton,
  Tooltip,
  Grid,
} from "@material-ui/core/";
import DeleteIcon from "@material-ui/icons/Delete";
import DisplayDate from "./DisplayDate";

interface IOwnProps {
  numSelected: number;
  toolbarDate: Date;
  removeSelectedRows: () => void;
  setToolbarDate: Dispatch<SetStateAction<Date>>;
  setPage: Dispatch<SetStateAction<number>>;
}

const useToolbarStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(1),
    },
    highlight:
      theme.palette.type === "light"
        ? {
            color: theme.palette.text.primary,
            backgroundColor: theme.palette.secondary.light,
          }
        : {
            color: theme.palette.text.primary,
            backgroundColor: theme.palette.secondary.dark,
          },
    title: {
      flex: "1 1 100%",
    },
  })
);

const TableToolbar = (props: IOwnProps) => {
  const classes = useToolbarStyles();

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: props.numSelected > 0,
      })}
    >
      {props.numSelected > 0 ? (
        <Typography
          className={classes.title}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {props.numSelected}{" "}
          {props.numSelected >= 2 ? "sélectionnés" : "sélectionné"}
        </Typography>
      ) : (
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="center"
        >
          <DisplayDate
            toolbarDate={props.toolbarDate}
            setToolbarDate={props.setToolbarDate}
            setPage={props.setPage}
          />
        </Grid>
      )}
      {props.numSelected > 0 && (
        <Tooltip title="Supprime la sélection" placement="right" arrow>
          <IconButton
            aria-label="supprime la sélection"
            onClick={() => props.removeSelectedRows()}
          >
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
};

export default TableToolbar;
