import React from "react";
import clsx from "clsx";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import MenuIcon from "@material-ui/icons/Menu";
import WbSunnyIcon from "@material-ui/icons/WbSunny";
import NightsStayIcon from "@material-ui/icons/NightsStay";
import {
  IconButton,
  Typography,
  Toolbar,
  AppBar,
  Tooltip,
} from "@material-ui/core/";
import { NavLink } from "react-router-dom";
import Logo from "./Logo";
import { IPropsHeaderAndSidebar } from "../interfaces";

// Taille min du menu
export const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      transition: theme.transitions.create(["margin", "width"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarShift: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
      transition: theme.transitions.create(["margin", "width"], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    hide: {
      display: "none",
    },
    contentTypo: {
      flexGrow: 1,
    },
  })
);

const Header = (props: IPropsHeaderAndSidebar) => {
  const classes = useStyles();

  return (
    <AppBar
      position="fixed"
      className={clsx(classes.appBar, {
        [classes.appBarShift]: props.drawerState,
      })}
    >
      <Toolbar>
        <Tooltip title="Ouvre le menu" aria-label="Ouvre le menu" arrow>
          <IconButton
            color="inherit"
            aria-label="ouvre le menu"
            onClick={() => props.handleAppState("drawerState", true)}
            edge="start"
            className={clsx(
              classes.menuButton,
              props.drawerState && classes.hide
            )}
          >
            <MenuIcon />
          </IconButton>
        </Tooltip>

        <Tooltip title="Vers l'accueil" aria-label="Vers l'accueil" arrow>
          <NavLink to={{ pathname: "/" }}>
            <Logo
              width={"4em"}
              height={"4em"}
              paddingRight={"1em"}
              paddingTop={"6px"}
            />
          </NavLink>
        </Tooltip>

        <Typography variant="h6" noWrap className={classes.contentTypo}>
          {"Money Management"}
        </Typography>
        <Tooltip
          title="Bascule le thème de couleurs (sombre/clair)"
          aria-label="bascule le thème de couleurs"
          arrow
        >
          <IconButton
            color="inherit"
            onClick={() => props.handleAppState("darkState")}
          >
            {props.darkState ? <NightsStayIcon /> : <WbSunnyIcon />}
          </IconButton>
        </Tooltip>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
