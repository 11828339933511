import { InputAdornment, TextField } from "@material-ui/core";
import React from "react";
import roundedNumber from "../Account/roundedNumber";
import { IProrataOrAccountHistoric } from "../interfaces";

type OwnProps = {
  prorataOrAccount: IProrataOrAccountHistoric;
  indexOfHistoric: number;
  handleChangeHistoric: (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    indexOfHistoric: number
  ) => void;
};

const CardItemsProrataOrAccounts = (props: OwnProps) => {
  const isAccountOrProrata = (rawNumber: number, type: string): number => {
    if (type === "prorataValue") {
      return roundedNumber(rawNumber * 100);
    } else {
      return roundedNumber(rawNumber);
    }
  };

  return (
    <>
      <TextField
        type="date"
        value={props.prorataOrAccount.dateOfProrataOrAccount}
        onChange={(e) => props.handleChangeHistoric(e, props.indexOfHistoric)}
        id={"dateOfProrataOrAccount"}
      />

      <TextField
        type="number"
        value={isAccountOrProrata(
          props.prorataOrAccount.prorataValue,
          "prorataValue"
        )}
        onChange={(e) => props.handleChangeHistoric(e, props.indexOfHistoric)}
        inputProps={{ step: 0.01 }}
        InputProps={{
          endAdornment: <InputAdornment position="end">% </InputAdornment>,
        }}
        id={"prorataValue"}
      />

      <TextField
        type="number"
        value={isAccountOrProrata(
          props.prorataOrAccount.accountValue,
          "accountValue"
        )}
        onChange={(e) => props.handleChangeHistoric(e, props.indexOfHistoric)}
        inputProps={{ step: 0.01 }}
        InputProps={{
          endAdornment: <InputAdornment position="end">€</InputAdornment>,
        }}
        id={"accountValue"}
      />
    </>
  );
};

export default CardItemsProrataOrAccounts;
