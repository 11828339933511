import React from "react";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import { ListItemIcon, ListItemText, ListItem } from "@material-ui/core/";
import { NavLink } from "react-router-dom";

const useStyles = makeStyles(() =>
  createStyles({
    linkRouter: {
      color: "inherit",
      textDecoration: "none",
      width: "100%",
    },
    addPadding: {
      paddingLeft: "2rem",
    },
  })
);

interface IProps {
  linkLocate: string;
  linkText: string;
  addPadding?: boolean;
  icon: JSX.Element;
}

const SidebarItem = (props: IProps) => {
  const classes = useStyles();

  return (
    <NavLink to={{ pathname: props.linkLocate }} className={classes.linkRouter}>
      <ListItem button className={props.addPadding ? classes.addPadding : ""}>
        <ListItemIcon>{props.icon}</ListItemIcon>
        <ListItemText primary={props.linkText} />
      </ListItem>
    </NavLink>
  );
};

export default SidebarItem;
