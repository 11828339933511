import React, { useCallback, useEffect } from "react";
import { FieldError, useForm } from "react-hook-form";
import {
  makeStyles,
  createStyles,
  Typography,
  Theme,
  Fab,
  Tooltip,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import { useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import { IData, IParamsAccount, IDropDownListsData } from "../interfaces";
import { GET_DROPDOWN_LIST } from "../queries/dropDowLists";
import InputText from "./InputText";
import InputSelect from "./InputSelect";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginTop: "2rem",
    },
    containerInputs: {
      display: "grid",
      [theme.breakpoints.down("sm")]: {
        gridTemplateColumns: "1fr",
      },
      [theme.breakpoints.between("sm", "md")]: {
        gridTemplateColumns: "2fr 1fr 1fr 1fr",
      },
      [theme.breakpoints.up("md")]: {
        gridTemplateColumns: (props: IParamsAccount): string =>
          props.typeAccount === "common"
            ? "2fr 1fr 1fr 1fr 1fr 1fr"
            : "2fr 1fr 1fr 1fr 1fr 1fr 1fr",
      },
      gridGap: "1rem",
      marginTop: "1rem",
    },

    containerButtonSubmit: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      margin: theme.spacing(2),
      " & > * ": {
        padding: theme.spacing(2),
      },
    },
  })
);

type OwnProps = {
  addRowToData: (newRow: IData) => Promise<boolean>;
  modifyRowInData: (newRow: IData) => Promise<boolean>;
  selected: IData[];
};

const InsertOrModifyARow = (props: OwnProps) => {
  const { data: dropDownLists } = useQuery<IDropDownListsData>(
    GET_DROPDOWN_LIST,
    {
      variables: { name: ["kindOfAmountList", "paymentMethodList"] },
    }
  );

  const { typeAccount } = useParams<IParamsAccount>();
  const classes = useStyles({ typeAccount });
  const { register, handleSubmit, errors, reset } = useForm<IData>();

  const onSubmit = (data: IData): void => {
    const isModify: boolean = isRowSelected();

    if (isModify) {
      let dataWithID = JSON.parse(JSON.stringify(data));
      dataWithID = { ...dataWithID, _id: props.selected[0]._id };

      props.modifyRowInData(dataWithID).then(() => reset({}));
    } else {
      props.addRowToData(data).then(() => reset({}));
    }
  };

  const isRowSelected = useCallback((): boolean => {
    if (props.selected.length > 0) return true;

    return false;
  }, [props.selected.length]);

  useEffect((): void => {
    if (isRowSelected()) {
      reset(props.selected[0]);
    } else {
      reset({});
    }
  }, [reset, isRowSelected, props.selected]);

  const renderAMessage = (): string => {
    const rowSelect: boolean = isRowSelected();
    const message: string = rowSelect
      ? "Modifie la ligne sélectionnée"
      : "Ajoute une ligne au tableau";

    return message;
  };

  const wichOptionsForSelect = (): string[] => {
    return typeAccount === "common"
      ? ["", "Margaux", "Cédric"]
      : dropDownLists?.getDropDownLists[0].list || [""];
  };

  const errorMessage = (errors: FieldError | undefined): string => {
    if (errors && errors.message !== undefined) return errors.message;

    return "";
  };

  return (
    <div className={classes.root}>
      <Typography variant={"body1"}>{renderAMessage() + " :"}</Typography>
      <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        <div className={classes.containerInputs}>
          <InputText
            label="Date"
            type="date"
            name="dateOfAmount"
            isLabelShrink={true}
            register={register}
            error={errorMessage(errors.dateOfAmount)}
          />
          <InputText
            label="Libellé"
            type="text"
            name="name"
            isLabelShrink={isRowSelected()}
            register={register}
            error={errorMessage(errors.name)}
          />
          <InputText
            label="Montant"
            type="number"
            name="amount"
            isLabelShrink={isRowSelected()}
            register={register}
            error={errorMessage(errors.amount)}
          />

          <InputSelect
            id={typeAccount === "common" ? "whoPaid" : "paymentMethod"}
            label={typeAccount === "common" ? "Payeur" : "Paiement"}
            isLabelShrink={isRowSelected()}
            options={wichOptionsForSelect()}
            register={register}
            error={errorMessage(
              typeAccount === "common" ? errors.whoPaid : errors.paymentMethod
            )}
          />
          <InputSelect
            id={"kindOfAmount"}
            label={"Dépense"}
            isLabelShrink={isRowSelected()}
            options={dropDownLists?.getDropDownLists[1].list || [""]}
            register={register}
            error={errorMessage(errors.kindOfAmount)}
          />

          <InputSelect
            id={typeAccount === "common" ? "isProrata" : "isCommon"}
            label={typeAccount === "common" ? "Prorata" : "Commun"}
            isLabelShrink={isRowSelected()}
            options={["", "Oui", "Non"]}
            register={register}
            error={errorMessage(
              typeAccount === "common" ? errors.whoPaid : errors.isCommon
            )}
          />
          <InputSelect
            id={"isRealised"}
            label={"Effectué"}
            isLabelShrink={isRowSelected()}
            options={["", "OK"]}
            register={register}
            error={errorMessage(errors.isRealised)}
            isHidden={typeAccount === "common"}
          />
        </div>

        <div className={classes.containerButtonSubmit}>
          <Tooltip title={renderAMessage()}>
            <Fab type="submit" color="primary" aria-label="add">
              <AddIcon />
            </Fab>
          </Tooltip>
        </div>
      </form>
    </div>
  );
};

export default InsertOrModifyARow;
