import React, { useEffect, useState } from "react";
import { useLazyQuery, useMutation } from "@apollo/client";
import { useParams } from "react-router-dom";
import {
  Button,
  createStyles,
  makeStyles,
  Theme,
  Tooltip,
} from "@material-ui/core";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import HeaderAndSidebar from "../Theme/HeaderAndSidebar";
import { IDarkModeProps, IData, IParamsAccount } from "../interfaces";
import DataDisplayTables from "./DataDisplayTransactions";
import InsertOrModifyARow from "./InsertOrModifyARow";
import {
  GET_TRANSACTIONS,
  ADD_TRANSACTION,
  UPDATE_TRANSACTION,
  DELETE_TRANSACTIONS,
  SET_MONTH_TEMPLATE,
} from "../queries/transactions";
import DisplayAlert from "./DisplayAlert";
import TitlePage from "../Theme/TitlePage";
import useDateForQuery from "./useDateForQuery";
interface ITransactionsData {
  getTransactions: IData[];
}

interface ISearchTransaction {
  _id: string;
}

type variablesQueryType = {
  start: Date | undefined;
  end: Date | undefined;
  typeAccount: string | undefined;
};

interface IQueryAddOrUpdateData extends IData {
  whereDisplay: string[];
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    containerButtonLoadTemplate: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      margin: theme.spacing(2),
      " & > * ": {
        padding: theme.spacing(2),
      },
    },
  })
);

const TablesMain = (props: IDarkModeProps) => {
  const classes = useStyles();

  const [selected, setSelected] = useState<IData[]>([]);
  const [backendMessage, setBackendMessage] = useState<string>("");
  const [toolbarDate, setToolbarDate] = useState<Date>(new Date());
  const { typeAccount } = useParams<IParamsAccount>();
  const dateForQuery = useDateForQuery(toolbarDate);

  const variablesQuery: variablesQueryType = {
    start: dateForQuery?.start,
    end: dateForQuery?.end,
    typeAccount,
  };

  const [
    getTransactionsData,
    { data, refetch },
  ] = useLazyQuery<ITransactionsData>(GET_TRANSACTIONS, {
    variables: variablesQuery,
  });
  const [addTransaction] = useMutation<IData>(ADD_TRANSACTION);
  const [updateTransaction] = useMutation<ITransactionsData>(
    UPDATE_TRANSACTION
  );
  const [deleteTransactions] = useMutation<ISearchTransaction>(
    DELETE_TRANSACTIONS
  );
  const [setMonthTemplate] = useMutation(SET_MONTH_TEMPLATE);

  useEffect(() => {
    getTransactionsData();
    setSelected([]);
  }, [typeAccount, refetch, getTransactionsData, dateForQuery]);

  const filterTableById = (_id: string, tableSearch: IData[]): IData[] => {
    const results: IData[] = tableSearch.filter(
      (row: IData) => row._id === _id
    );

    return results;
  };

  const transformRowForQuery = (
    row: IData,
    isModify?: boolean
  ): IQueryAddOrUpdateData => {
    let shallowRow: IQueryAddOrUpdateData = JSON.parse(JSON.stringify(row));
    const whereDisplay = answerToWhereDisplay(row);

    if (typeAccount === "common") {
      const paymentMethod = isModify ? {} : { paymentMethod: "" };
      return {
        ...shallowRow,
        ...whereDisplay,
        ...paymentMethod,
        isCommon: "Oui",
      };
    } else {
      let extraValue = {};

      if (isModify) {
        extraValue = {};
      } else {
        const prorataCommonRefund =
          row.kindOfAmount === "Remboursements-Commun"
            ? { isProrata: "Non" }
            : { isProrata: "Oui" };
        extraValue = { ...prorataCommonRefund, whoPaid: typeAccount };
      }

      if (row.isProrata) {
        extraValue = { ...extraValue, isProrata: row.isProrata };
      }

      if (row.whoPaid) {
        extraValue = { ...extraValue, whoPaid: row.whoPaid };
      }

      return {
        ...shallowRow,
        ...whereDisplay,
        ...extraValue,
      };
    }
  };

  const answerToWhereDisplay = (row: IData) => {
    let whereDisplay: string[] = [];

    if (
      row.kindOfAmount === "Remboursements-Crédit" ||
      row.kindOfAmount === "Remboursements-Commun"
    ) {
      whereDisplay.push("Margaux", "Cédric", "common");
    } else {
      const whoPaid: string =
        typeAccount === "common" ? row.whoPaid : typeAccount;
      const isCommon: boolean =
        typeAccount === "common" || row.isCommon === "Oui";

      whereDisplay.push(whoPaid);

      if (isCommon) whereDisplay.push("common");
    }

    return { whereDisplay };
  };

  const addRowToData = (row: IData): Promise<boolean> => {
    return new Promise((resolve, reject) => {
      const newRow: IQueryAddOrUpdateData = transformRowForQuery(row);

      addTransaction({
        variables: newRow,
        refetchQueries: [
          {
            query: GET_TRANSACTIONS,
            variables: variablesQuery,
          },
        ],
      })
        .then(() => {
          renderBackendMessageWithKeyWord("ajoutée");
          resolve(true);
        })
        .catch(() => reject(false));
    });
  };

  const modifyRowInData = (row: IData): Promise<boolean> => {
    return new Promise((resolve, reject) => {
      const newRow: IQueryAddOrUpdateData = transformRowForQuery(row, true);

      updateTransaction({
        variables: newRow,
        refetchQueries: [
          {
            query: GET_TRANSACTIONS,
            variables: variablesQuery,
          },
        ],
      })
        .then(() => {
          renderBackendMessageWithKeyWord("modifiée");
          setSelected([]);
          resolve(true);
        })
        .catch(() => reject(false));
    });
  };

  const selectAllRows = (event: React.ChangeEvent<HTMLInputElement>): void => {
    if (event.target.checked && data) {
      setSelected(data?.getTransactions);
    } else {
      setSelected([]);
    }
  };

  const selectRow = (_id: string): void => {
    const isMatch: boolean = isSelected(_id);
    let shallowSelected: IData[] = JSON.parse(JSON.stringify(selected));

    if (isMatch) {
      shallowSelected = shallowSelected.filter(
        (item: IData) => item._id !== _id
      );
    } else {
      const rowData: IData[] = filterTableById(
        _id,
        data?.getTransactions || []
      );
      shallowSelected = [...shallowSelected, ...rowData];
    }

    setSelected(shallowSelected);
  };

  const removeSelectedRows = (): void => {
    const _ids: string[] = selected.map((item: IData) => item._id);

    deleteTransactions({
      variables: {
        _ids,
      },
      refetchQueries: [
        {
          query: GET_TRANSACTIONS,
          variables: variablesQuery,
        },
      ],
    }).then(() => {
      const isPlural: boolean = _ids.length >= 2;
      const isPluralKeyWord = isPlural ? "supprimées" : "supprimée";

      renderBackendMessageWithKeyWord(isPluralKeyWord, isPlural);
      setSelected([]);
    });
  };

  const isSelected = (_id: string): boolean => {
    const isMatch: IData[] = filterTableById(_id, selected);
    return isMatch.length > 0;
  };

  const renderBackendMessageWithKeyWord = (
    keyWord: string,
    isPlurial: boolean = false
  ) => {
    const message: string =
      (isPlurial ? "Les lignes ont été " : "La ligne a été ") +
      keyWord +
      " avec succès! ";

    setBackendMessage(message);
  };

  const renderTitle = (): string => {
    return typeAccount === "common"
      ? "Compte commun"
      : `Compte de ${typeAccount}`;
  };

  const onClikOnSetMonthTemplateButton = () =>
    setMonthTemplate({
      variables: {
        start: dateForQuery?.start,
      },
      refetchQueries: [
        {
          query: GET_TRANSACTIONS,
          variables: variablesQuery,
        },
      ],
    });

  return (
    <>
      <HeaderAndSidebar {...props}>
        <>
          <TitlePage title={renderTitle()} />
          <DataDisplayTables
            data={data?.getTransactions || []}
            selected={selected}
            toolbarDate={toolbarDate}
            selectAllRows={selectAllRows}
            selectRow={selectRow}
            removeSelectedRows={removeSelectedRows}
            isSelected={isSelected}
            setToolbarDate={setToolbarDate}
          />
          {data?.getTransactions && data.getTransactions.length === 0 && (
            <div className={classes.containerButtonLoadTemplate}>
              <Tooltip title="Permet de charger un template contenant les transactions mensuelles">
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  onClick={() => onClikOnSetMonthTemplateButton()}
                  startIcon={<CloudUploadIcon />}
                >
                  Charger le template mensuel
                </Button>
              </Tooltip>
            </div>
          )}
          <InsertOrModifyARow
            addRowToData={addRowToData}
            modifyRowInData={modifyRowInData}
            selected={selected}
          />
          <DisplayAlert message={backendMessage} severity="success" />
        </>
      </HeaderAndSidebar>
    </>
  );
};

export default TablesMain;
