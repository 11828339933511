import { gql } from "@apollo/client";

export const GET_PRORATA_OR_ACCOUNT_HISTORICS = gql`
  query getProrataOrAccountHistorics($dateOfProrataOrAccount: String) {
    getProrataOrAccountHistorics(
      dateOfProrataOrAccount: $dateOfProrataOrAccount
    ) {
      _id
      prorataValue
      accountValue
      dateOfProrataOrAccount
    }
  }
`;

export const UPDATE_PRORATA_OR_ACCOUNT_HISTORIC = gql`
  mutation UpdateProrataOrAccountHistoric(
    $dateOfProrataOrAccount: String!
    $accountValue: Float
    $prorataValue: Float
  ) {
    updateProrataOrAccountHistoric(
      dateOfProrataOrAccount: $dateOfProrataOrAccount
      prorataValue: $prorataValue
      accountValue: $accountValue
    ) {
      n
      nModified
    }
  }
`;
