import React from "react";
import { Typography } from "@material-ui/core";
import { Variant } from "@material-ui/core/styles/createTypography";
import AmountColored from "./AmountColored";

type OwnProps = {
  variantTitle: Variant;
  title: string;
  amount: number;
  isAmountHidden?: boolean;
};

const ItemCharges = (props: OwnProps) => {
  return (
    <>
      <Typography variant={props.variantTitle}>{`${props.title}`}</Typography>
      <AmountColored
        amount={props.amount}
        isHidden={props.isAmountHidden ? true : false}
      />
    </>
  );
};

export default ItemCharges;
