import {
  Grid,
  Card,
  CardContent,
  CardHeader,
  createStyles,
  makeStyles,
} from "@material-ui/core";
import React from "react";

const useStyles = makeStyles(() =>
  createStyles({
    cardHeader: {
      display: "flex",
      justifyContent: "center",
    },
  })
);

type OwnProps = {
  children: JSX.Element;
  titleCard: string;
};

const CardItems = (props: OwnProps) => {
  const classes = useStyles();

  return (
    <Grid item xs="auto">
      <Card>
        <div className={classes.cardHeader}>
          <CardHeader title={props.titleCard} />
        </div>
        <CardContent>{props.children}</CardContent>
      </Card>
    </Grid>
  );
};

export default CardItems;
