import { Button, createStyles, makeStyles, Theme } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import clsx from "clsx";
import CardItems from "../Account/CardItems";
import SubTitle from "../Account/SubTitle";
import {
  IProrataOrAccountHistoric,
  IProrataOrAccountHistoricData,
} from "../interfaces";
import CardItemsProrataOrAccounts from "./CardItemsProrataOrAccounts";
import roundedNumber from "../Account/roundedNumber";
import CalculateProrata from "./CalculateProrata";
import ButtonAddElement from "./ButtonAddElement";
import { useMutation, useLazyQuery } from "@apollo/client";
import {
  GET_PRORATA_OR_ACCOUNT_HISTORICS,
  UPDATE_PRORATA_OR_ACCOUNT_HISTORIC,
} from "../queries/prorataOrAccount";
import DisplayAlert from "../Transactions/DisplayAlert";

type State = {
  severity: "error" | "success" | "info";
  msg: string;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "grid",
      gridTemplateColumns: "2fr 1fr 1fr",
      alignItems: "center",
      rowGap: "12px",
      columnGap: "12px",
    },
    containerButton: {
      display: "grid",
      gridTemplateColumns: "1fr",
      marginTop: "12px",
      rowGap: "12px",
    },
    addMargin: {
      marginBottom: theme.spacing(3),
    },
  })
);

const ManagementProrata = () => {
  const classes = useStyles();

  const [
    getProrataOrAccountHistorics,
    { data: prorataOrAccountHistorics },
  ] = useLazyQuery<IProrataOrAccountHistoricData>(
    GET_PRORATA_OR_ACCOUNT_HISTORICS,
    { fetchPolicy: "cache-and-network" }
  );
  const [historics, setHistorics] = useState<IProrataOrAccountHistoric[]>([]);
  const [afterSubmit, setAfterSubmit] = useState<State>({
    severity: "success",
    msg: "",
  });

  const [
    updateProrataOrAccountHistoric,
  ] = useMutation<IProrataOrAccountHistoricData>(
    UPDATE_PRORATA_OR_ACCOUNT_HISTORIC
  );

  useEffect(() => {
    getProrataOrAccountHistorics();
  }, [getProrataOrAccountHistorics]);

  useEffect(() => {
    if (prorataOrAccountHistorics?.getProrataOrAccountHistorics)
      setHistorics(prorataOrAccountHistorics?.getProrataOrAccountHistorics);
  }, [setHistorics, prorataOrAccountHistorics]);

  const addElement = (): void => {
    const newDropDownList: IProrataOrAccountHistoric[] = JSON.parse(
      JSON.stringify(historics)
    );

    const dateOfProrataOrAccountMonth = new Date(
      newDropDownList[0].dateOfProrataOrAccount
    ).getMonth();

    const dateOfProrataOrAccountMoreThan1Month = new Date(
      newDropDownList[0].dateOfProrataOrAccount
    );
    dateOfProrataOrAccountMoreThan1Month.setMonth(
      dateOfProrataOrAccountMonth + 1
    );

    newDropDownList.push({
      dateOfProrataOrAccount: dateOfProrataOrAccountMoreThan1Month.toLocaleDateString(
        "fr-CA"
      ),
      accountValue: newDropDownList[0].accountValue,
      prorataValue: newDropDownList[0].prorataValue,
    });
    setHistorics(newDropDownList);
  };

  const handleChangeHistoric = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    indexOfHistoric: number
  ): void => {
    const shallowHistorics: IProrataOrAccountHistoric[] = JSON.parse(
      JSON.stringify(historics)
    );
    const { id, value } = event.target;

    switch (id) {
      case "prorataValue":
        shallowHistorics[indexOfHistoric][id] = parseFloat(value) / 100;
        break;

      case "accountValue":
        shallowHistorics[indexOfHistoric][id] = roundedNumber(
          parseFloat(value)
        );
        break;

      default:
        shallowHistorics[indexOfHistoric][id] = value.toString();
        break;
    }

    setHistorics(shallowHistorics);
  };

  const searchModificationBeforeSave = (): IProrataOrAccountHistoric[] => {
    const shallowDatas = historics.reduce<IProrataOrAccountHistoric[]>(
      (acc, curr, index) => {
        const verifyEachKey: boolean[] = Object.keys(curr).map(
          (key) =>
            !prorataOrAccountHistorics?.getProrataOrAccountHistorics[index] ||
            curr[key] !==
              prorataOrAccountHistorics?.getProrataOrAccountHistorics[index][
                key
              ]
        );
        if (verifyEachKey.includes(true)) return [...acc, curr];

        return acc;
      },
      []
    );

    return shallowDatas;
  };

  const shouldDateOfProrataOrAccountIsFirstDayOfMonth = (
    rawDatas: IProrataOrAccountHistoric[]
  ): IProrataOrAccountHistoric[] => {
    const newData = rawDatas.reduce<IProrataOrAccountHistoric[]>(
      (acc, curr) => {
        const dayOfDateOfProrataOrAccount = new Date(
          curr.dateOfProrataOrAccount
        ).getDate();

        if (dayOfDateOfProrataOrAccount !== 1) {
          const shallowDateOfProrataOrAccount = new Date(
            curr.dateOfProrataOrAccount
          );
          shallowDateOfProrataOrAccount.setDate(1);
          acc.push({
            ...curr,
            dateOfProrataOrAccount: shallowDateOfProrataOrAccount.toUTCString(),
          });
        } else {
          acc.push(curr);
        }
        return acc;
      },
      []
    );

    return newData;
  };

  const saveModification = () => {
    // Reset message
    setAfterSubmit({
      severity: "error",
      msg: "",
    });

    const updates: IProrataOrAccountHistoric[] = searchModificationBeforeSave();

    const updatesVerify = shouldDateOfProrataOrAccountIsFirstDayOfMonth(
      updates
    );

    if (updatesVerify.length > 0) {
      let promises: any = [];

      updatesVerify.forEach((modification) => {
        promises.push(
          updateProrataOrAccountHistoric({
            variables: modification,
          })
        );
      });
      Promise.all(promises)
        .then(() => {
          getProrataOrAccountHistorics();
          setAfterSubmit({
            severity: "success",
            msg: "Mise à jour effectuée",
          });
        })
        .catch(() => {
          setAfterSubmit({
            severity: "error",
            msg: "Un problème est survenu, réessayer plus tard",
          });
        });
    } else {
      setAfterSubmit({
        severity: "info",
        msg:
          "Aucune mise à jour a été effectuée, car aucun changement n'a été détecté",
      });
    }
  };

  return (
    <CardItems titleCard={`Gestion du compte et du prorata`}>
      <>
        <CalculateProrata />

        <SubTitle
          isCenterTitle={true}
          mainTitle="Historiques"
          displayFirstDivider={true}
          displaySecondDivider={true}
          displayAvatar={false}
        />

        <div className={clsx(classes.container, classes.addMargin)}>
          {historics.map((item, index) => (
            <CardItemsProrataOrAccounts
              prorataOrAccount={item}
              indexOfHistoric={index}
              handleChangeHistoric={handleChangeHistoric}
              key={item + index.toString()}
            />
          ))}
        </div>

        <div className={clsx(classes.containerButton, classes.addMargin)}>
          <ButtonAddElement addElement={addElement} />

          <Button
            variant="contained"
            color="primary"
            onClick={() => saveModification()}
          >
            Sauvegarder
          </Button>

          <DisplayAlert
            severity={afterSubmit.severity}
            message={afterSubmit.msg}
          />
        </div>
      </>
    </CardItems>
  );
};

export default ManagementProrata;
