import { createStyles, makeStyles, Theme, Typography } from "@material-ui/core";
import React, { useState } from "react";
import clsx from "clsx";
import SubTitle from "../Account/SubTitle";
import CardItemsWages from "./CardItemsWages";
import roundedNumber from "../Account/roundedNumber";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "grid",
      gridTemplateColumns: "1fr 1fr ",
      alignItems: "center",
      rowGap: "12px",
      columnGap: "12px",
    },
    addMargin: {
      marginBottom: theme.spacing(3),
    },
  })
);

const CalculateProrata = () => {
  const classes = useStyles();

  const [wage, setWage] = useState<number[]>([1, 1]);

  const handleChangeWage = (value: number, indexOfWho: number): void => {
    const newWages = [...wage];
    newWages[indexOfWho] = value;
    setWage(newWages);
  };

  const calculateProrata = (): number =>
    roundedNumber((wage[0] / (wage[0] + wage[1])) * 100);

  return (
    <>
      <SubTitle
        isCenterTitle={true}
        mainTitle="Calculette du prorata (après impôts)"
        displayFirstDivider={true}
        displaySecondDivider={true}
        displayAvatar={false}
      />

      <div className={clsx(classes.container, classes.addMargin)}>
        {["Margaux", "Cédric"].map((item, index) => (
          <CardItemsWages
            who={item}
            wage={wage[index]}
            indexOfHistoric={index}
            handleChangeWage={handleChangeWage}
            key={item}
          />
        ))}
        <Typography variant="button">Calcul du prorata:</Typography>
        <Typography variant="button">{`${calculateProrata()}%`}</Typography>
      </div>
    </>
  );
};

export default CalculateProrata;
