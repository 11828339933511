import { gql } from "@apollo/client";

export const GET_TRANSACTIONS = gql`
  query GetTransactions($start: String!, $end: String!, $typeAccount: String!) {
    getTransactions(start: $start, end: $end, typeAccount: $typeAccount) {
      _id
      amount
      paymentMethod
      kindOfAmount
      name
      isCommon
      isRealised
      dateOfAmount
      whoPaid
      isProrata
    }
  }
`;

export const ADD_TRANSACTION = gql`
  mutation AddTransaction(
    $dateOfAmount: String!
    $name: String!
    $amount: Float!
    $kindOfAmount: String!
    $paymentMethod: String!
    $isCommon: String!
    $whoPaid: String!
    $isRealised: String
    $whereDisplay: [String!]
    $isProrata: String!
  ) {
    addTransaction(
      dateOfAmount: $dateOfAmount
      name: $name
      amount: $amount
      kindOfAmount: $kindOfAmount
      paymentMethod: $paymentMethod
      isCommon: $isCommon
      whoPaid: $whoPaid
      isRealised: $isRealised
      whereDisplay: $whereDisplay
      isProrata: $isProrata
    ) {
      _id
    }
  }
`;

export const UPDATE_TRANSACTION = gql`
  mutation UpdateTransaction(
    $_id: ID!
    $dateOfAmount: String
    $name: String
    $amount: Float
    $kindOfAmount: String
    $paymentMethod: String
    $isCommon: String
    $whoPaid: String
    $isRealised: String
    $whereDisplay: [String!]
    $isProrata: String
  ) {
    updateTransaction(
      _id: $_id
      isRealised: $isRealised
      dateOfAmount: $dateOfAmount
      name: $name
      amount: $amount
      kindOfAmount: $kindOfAmount
      paymentMethod: $paymentMethod
      isCommon: $isCommon
      whoPaid: $whoPaid
      whereDisplay: $whereDisplay
      isProrata: $isProrata
    ) {
      _id
    }
  }
`;

export const DELETE_TRANSACTIONS = gql`
  mutation DeleteTransactions($_ids: [ID!]) {
    deleteTransactions(_ids: $_ids) {
      deletedCount
    }
  }
`;

export const SET_MONTH_TEMPLATE = gql`
  mutation SetMonthTemplate($start: String!) {
    setMonthTemplate(start: $start) {
      n
    }
  }
`;
