import React from "react";
import { useTheme } from "@material-ui/core/styles";
import { Chart, registerables } from "chart.js";
import {
  Card,
  CardContent,
  CardHeader,
  createStyles,
  makeStyles,
} from "@material-ui/core";
import utils from "../Utils";
// When we run the test, registerables is undefined.
// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
Chart.register(...(registerables ?? []));

type OwnProps = {
  ArrayOfLabels: Array<String>;
  ArrayOfDataPositives: Array<number>;
  ArrayOfDataNegatives: Array<number>;
  chartTitle: string;
};

const useStyles = makeStyles(() =>
  createStyles({
    cardHeader: {
      display: "flex",
      justifyContent: "center",
    },
  })
);

const HorizontalBarStacked = (props: OwnProps) => {
  const classes = useStyles();
  const theme = useTheme();
  const {
    ArrayOfLabels,
    ArrayOfDataPositives,
    ArrayOfDataNegatives,
    chartTitle,
  } = props;

  const ref = React.useRef<null | HTMLCanvasElement>(null);

  React.useEffect(() => {
    if (ref.current) {
      const chart = new Chart(ref.current, {
        type: "bar",
        data: {
          labels: ArrayOfLabels,
          datasets: [
            {
              label: "Entrée",
              data: ArrayOfDataPositives,
              borderColor: utils.chart.CHART_COLORS.green,
              backgroundColor: utils.chart.CHART_COLORS.green,
            },
            {
              label: "Sortie",
              data: ArrayOfDataNegatives,
              borderColor: utils.chart.CHART_COLORS.red,
              backgroundColor: utils.chart.CHART_COLORS.red,
            },
          ],
        },
        options: {
          responsive: true,
          plugins: {
            legend: {
              labels: {
                color: theme.palette.type === "dark" ? "white" : "black",
              },
            },
          },
          interaction: {
            intersect: false,
            mode: "index",
          },
          scales: {
            x: {
              stacked: true,
              ticks: {
                color: theme.palette.type === "dark" ? "white" : "black",
              },
            },
            y: {
              stacked: true,
              ticks: {
                color: theme.palette.type === "dark" ? "white" : "black",
              },
            },
          },
        },
      });

      return () => {
        chart.destroy();
      };
    } else {
      return () => {};
    }
  }, [
    ref,
    ArrayOfDataNegatives,
    ArrayOfDataPositives,
    ArrayOfLabels,
    theme.palette.type,
  ]);

  return (
    <Card>
      <div className={classes.cardHeader}>
        <CardHeader title={chartTitle} />
      </div>
      <CardContent>
        <canvas ref={ref} />
      </CardContent>
    </Card>
  );
};

export default HorizontalBarStacked;
