import { useState, useEffect } from "react";

type QueryDateType = {
  start: Date;
  end: Date;
};

const useDateForQuery = (rawData?: Date) => {
  const [dateForQuery, setDateForQuery] = useState<QueryDateType>();

  useEffect(() => {
    if (rawData) {
      queryDate(rawData);
    } else {
      queryDate(new Date());
    }
  }, [rawData]);

  const queryDate = (date: Date): void => {
    // La date doit rester au format UTC car apollo fait une requête à la base de donnée sous se format. (Evite les surprises de GMT/heure d'été )
    const start: Date = new Date(
      Date.UTC(date.getFullYear(), date.getMonth(), 1)
    );

    const end: Date = new Date(
      Date.UTC(date.getFullYear(), date.getMonth() + 1, 1)
    );

    setDateForQuery({ start, end });
  };

  return dateForQuery;
};

export default useDateForQuery;
