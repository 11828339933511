import React from "react";
import { createStyles, makeStyles, Theme, Typography } from "@material-ui/core";

type OwnProps = {
  title: string;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      marginBottom: theme.spacing(2),
      display: "flex",
      justifyContent: "center",
    },
  })
);

const TitlePage = (props: OwnProps) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Typography variant="h4">{props.title}</Typography>
    </div>
  );
};

export default TitlePage;
