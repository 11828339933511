import { useEffect, useState, useCallback } from "react";
import { IAccountInfosCategories } from "../interfaces";

const useCategoriesInfoFilter = (
  rawCategoriesInfo: IAccountInfosCategories[],
  filterTypeName: string
) => {
  const [categoriesInfoFiltered, setCategoriesInfoFiltered] = useState<
    IAccountInfosCategories[]
  >(rawCategoriesInfo);

  const getIndexOfTitleItem = useCallback(
    (title: string): number =>
      rawCategoriesInfo.map((item) => item._id).indexOf(title),
    [rawCategoriesInfo]
  );

  const removeIndicatorForApolloCache = (
    curr: IAccountInfosCategories
  ): IAccountInfosCategories =>
    curr._id.startsWith("P-")
      ? { ...curr, _id: curr._id.split("P-")[1] }
      : curr;

  const reduceInfosCategories = useCallback(
    (startIndex: number, endIndex: number): IAccountInfosCategories[] => {
      const reducedInfo = rawCategoriesInfo.reduce(
        (
          acc: IAccountInfosCategories[],
          curr: IAccountInfosCategories,
          index: number
        ) => {
          if (index > startIndex && index <= endIndex) {
            return [...acc, removeIndicatorForApolloCache(curr)];
          }

          return acc;
        },
        []
      );

      return sortCategoriesInfo(reducedInfo);
    },
    [rawCategoriesInfo]
  );

  const sortCategoriesInfo = (
    infosCategories: IAccountInfosCategories[]
  ): IAccountInfosCategories[] => {
    const infosCategoriesSorted: IAccountInfosCategories[] = infosCategories.sort(
      (a, b) => a._id.localeCompare(b._id)
    );

    return infosCategoriesSorted;
  };

  const filterCategories = useCallback(
    (type: string): IAccountInfosCategories[] => {
      const startIndex: number =
        type === "common" ? 2 : getIndexOfTitleItem("sumCommon");
      const endIndex: number =
        type === "common"
          ? getIndexOfTitleItem("sumCommon")
          : getIndexOfTitleItem("sumPersonnal");

      const dataFiltered: IAccountInfosCategories[] = reduceInfosCategories(
        startIndex,
        endIndex
      );

      return dataFiltered;
    },
    [getIndexOfTitleItem, reduceInfosCategories]
  );

  useEffect(() => {
    if (rawCategoriesInfo.length > 0)
      setCategoriesInfoFiltered(filterCategories(filterTypeName));
  }, [rawCategoriesInfo.length, filterCategories, filterTypeName]);

  return categoriesInfoFiltered;
};

export default useCategoriesInfoFilter;
