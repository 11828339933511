import React, { useEffect, useState } from "react";
import { Snackbar } from "@material-ui/core";
import MuiAlert, { AlertProps } from "@material-ui/lab/Alert";

type OwnProps = {
  message: string;
  severity: "error" | "success" | "info";
  horizontal?: "left" | "center" | "right";
};

const DisplayAlert = (props: OwnProps) => {
  const [displayAlert, setDisplayAlert] = useState(false);

  useEffect(() => {
    if (props.message && props.message !== "") setDisplayAlert(true);
  }, [props.message]);

  const Alert = (props: AlertProps): JSX.Element => {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  };

  const handleCloseAlert = (
    event?: React.SyntheticEvent,
    reason?: string
  ): void => {
    if (reason === "clickaway") {
      return;
    }

    setDisplayAlert(false);
  };

  return (
    <Snackbar
      anchorOrigin={{
        vertical: "bottom",
        horizontal: props.horizontal ?? "right",
      }}
      open={displayAlert}
      autoHideDuration={5000}
      onClose={handleCloseAlert}
    >
      <Alert onClose={handleCloseAlert} severity={props.severity}>
        {props.message}
      </Alert>
    </Snackbar>
  );
};

export default DisplayAlert;
