import React from "react";
import { createStyles, makeStyles, Typography, Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    negativeAmount: {
      color:
        theme.palette.type === "dark"
          ? theme.palette.error.light
          : theme.palette.error.dark,
    },
    positiveAmount: {
      color:
        theme.palette.type === "dark"
          ? theme.palette.success.light
          : theme.palette.success.dark,
    },
    hidden: {
      display: "none !important",
    },
  })
);

type OwnProps = {
  amount: number;
  isHidden: boolean;
};

const AmountColored = (props: OwnProps) => {
  const classes = useStyles();

  const isAmountNegative = (amount: number): boolean => {
    return amount < 0;
  };

  const renderClassnameHiddenOrAmountColor = (): string => {
    if (props.isHidden) return classes.hidden;

    return isAmountNegative(props.amount)
      ? classes.negativeAmount
      : classes.positiveAmount;
  };
  return (
    <Typography
      variant="button"
      className={renderClassnameHiddenOrAmountColor()}
    >
      {`${props.amount} €`}
    </Typography>
  );
};

export default AmountColored;
