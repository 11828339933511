import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { ThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import { CssBaseline } from "@material-ui/core/";
import { blue, orange, indigo } from "@material-ui/core/colors";
import { frFR } from "@material-ui/core/locale";
import { ApolloProvider } from "@apollo/client";
import { ApolloClient, NormalizedCacheObject } from "@apollo/client";
import { cache } from "./cache";

import Dashboard from "./Dashboard";
import Transactions from "./Transactions";
import Accounts from "./Account";
import { URL_BACKEND } from "./config";
import Administration from "./Administration";
import Statistics from "./Statistics";

const client: ApolloClient<NormalizedCacheObject> = new ApolloClient({
  uri: URL_BACKEND,
  cache,
});

const App = () => {
  const [darkState, setdarkState] = useState<boolean>(false);
  const palletType = darkState ? "dark" : "light";
  const [drawerState, setDrawerState] = useState<boolean>(false);

  useEffect(() => {
    if (
      window.matchMedia &&
      window.matchMedia("(prefers-color-scheme: dark)").matches
    ) {
      setdarkState(true);
    }
  }, []);

  const theme = createMuiTheme(
    {
      palette: {
        type: palletType,
        primary: palletType === "dark" ? indigo : blue,
        secondary: {
          main: orange["A400"],
        },
      },
    },
    frFR
  );

  const handleAppState = (params: string, value: boolean = false): void => {
    if (params === "darkState") setdarkState(!darkState);
    if (params === "drawerState") setDrawerState(value);
  };

  return (
    <ApolloProvider client={client}>
      <Router>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Switch>
            <Route exact path="/">
              <Dashboard
                darkState={darkState}
                handleAppState={handleAppState}
                drawerState={drawerState}
              />
            </Route>
            <Route exact path="/transactions/:typeAccount">
              <Transactions
                darkState={darkState}
                handleAppState={handleAppState}
                drawerState={drawerState}
              />
            </Route>
            <Route exact path="/accounts/">
              <Accounts
                darkState={darkState}
                handleAppState={handleAppState}
                drawerState={drawerState}
              />
            </Route>
            <Route exact path="/statistics/">
              <Statistics
                darkState={darkState}
                handleAppState={handleAppState}
                drawerState={drawerState}
              />
            </Route>
            <Route exact path="/administration/">
              <Administration
                darkState={darkState}
                handleAppState={handleAppState}
                drawerState={drawerState}
              />
            </Route>
          </Switch>
        </ThemeProvider>
      </Router>
    </ApolloProvider>
  );
};

export default App;
