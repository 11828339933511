import React from "react";
import { IconButton, Divider, List, Drawer } from "@material-ui/core/";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import DashboardIcon from "@material-ui/icons/Dashboard";
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
import BuildIcon from "@material-ui/icons/Build";
import BarChartIcon from "@material-ui/icons/BarChart";
import SidebarItem from "./SidebarItem";
import SidebarDropDown from "./SidebarDropDown";
import { IPropsHeaderAndSidebar } from "../interfaces";
import { drawerWidth } from "./Header";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    drawerPaper: {
      width: drawerWidth,
    },
    drawerHeader: {
      display: "flex",
      alignItems: "center",
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
      justifyContent: "flex-end",
    },
  })
);

const Sidebar = (props: IPropsHeaderAndSidebar) => {
  const classes = useStyles();

  return (
    <Drawer
      className={classes.drawer}
      variant="persistent"
      anchor="left"
      open={props.drawerState}
      classes={{
        paper: classes.drawerPaper,
      }}
    >
      <div className={classes.drawerHeader}>
        <IconButton onClick={() => props.handleAppState("drawerState", false)}>
          <ChevronLeftIcon />
        </IconButton>
      </div>
      <Divider />
      <List>
        <SidebarItem
          linkLocate={"/"}
          linkText={"Tableau de bord"}
          icon={<DashboardIcon />}
        />
        <SidebarDropDown />
        <SidebarItem
          linkLocate={"/accounts/"}
          linkText={"Comptes"}
          icon={<AccountBalanceIcon />}
        />
        <SidebarItem
          linkLocate={"/statistics/"}
          linkText={"Statistiques"}
          icon={<BarChartIcon />}
        />
        <SidebarItem
          linkLocate={"/administration/"}
          linkText={"Administration"}
          icon={<BuildIcon />}
        />
      </List>
      <Divider />
    </Drawer>
  );
};

export default Sidebar;
