import { createStyles, makeStyles } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      display: "grid",
      gridTemplateColumns: "1fr 1fr",
      " & *:nth-child(even)": {
        display: "grid",
        justifyContent: "end",
      },
    },
  })
);

type OwnProps = {
  children: JSX.Element;
};

const GridContainer = (props: OwnProps) => {
  const classes = useStyles();

  return <div className={classes.container}>{props.children}</div>;
};

export default GridContainer;
