const roundedNumber = (rawNumber: number | null | undefined) => {
  const nullToZero = (value: null | number | undefined): number =>
    value === null || value === undefined ? 0 : floatWithTwoNumber(value);

  const floatWithTwoNumber = (rawNumber: number): number => {
    return Math.round(rawNumber * 100) / 100;
  };

  return nullToZero(rawNumber);
};

export default roundedNumber;
