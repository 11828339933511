import React from "react";
import {
  InputLabel,
  Select,
  FormControl,
  FormHelperText,
  makeStyles,
  createStyles,
} from "@material-ui/core";

interface IInputSelect {
  id: string;
  label: string;
  isLabelShrink: boolean;
  options: string[];
  register: any;
  error: string;
  isHidden?: boolean;
}

const useStyles = makeStyles(() =>
  createStyles({
    centered: {
      margin: "auto",
    },
    notDisplay: {
      display: "none",
    },
  })
);

const InputSelect = (props: IInputSelect) => {
  const classes = useStyles();

  const isRequired = (): boolean | string => {
    return props.id === "isRealised" || props.isHidden
      ? false
      : "Séléctionner un élément";
  };

  const isError: boolean = props.error !== "" ? true : false;
  return (
    <FormControl
      error={isError}
      variant="outlined"
      className={props.isHidden ? classes.notDisplay : ""}
    >
      <InputLabel
        {...(props.isLabelShrink && { shrink: true })}
        htmlFor={props.id}
      >
        {props.label}
      </InputLabel>
      <Select
        native
        label={props.label}
        inputProps={{
          name: props.id,
          id: props.id,
        }}
        inputRef={props.register({
          required: isRequired(),
        })}
      >
        {props.options.map((option) => (
          <option key={option === "" ? "none" : option} value={option}>
            {option}
          </option>
        ))}
      </Select>
      <FormHelperText className={classes.centered} hidden={!isError}>
        {props.error}
      </FormHelperText>
    </FormControl>
  );
};

export default InputSelect;
