import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Paper,
  Checkbox,
} from "@material-ui/core/";
import { useParams } from "react-router-dom";
import { IData, IParamsAccount } from "../interfaces";
import TableHeader from "./TableHeader";
import TableToolbar from "./TableToolBar";

// Function permettant de classer par ordre croissant / décroissant
function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = "asc" | "desc";

// Function permettant de comparer le type d'ordre (croissant VS décroissant)
function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string }
) => number {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      [theme.breakpoints.down("lg")]: {
        maxWidth: "100%",
      },
      [theme.breakpoints.up("lg")]: {
        maxWidth: "80%",
      },
      margin: "auto",
    },
    paper: {
      width: "100%",
      marginBottom: theme.spacing(2),
    },
    visuallyHidden: {
      border: 0,
      clip: "rect(0 0 0 0)",
      height: 1,
      margin: -1,
      overflow: "hidden",
      padding: 0,
      position: "absolute",
      top: 20,
      width: 1,
    },
    container: {
      maxHeight: "30em",
    },
    notRenderingByBreakPoints: {
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
    },
    notRenderingCommon: {
      display: "none",
    },
    amountDebit: {
      color:
        theme.palette.type === "dark"
          ? theme.palette.error.light
          : theme.palette.error.dark,
    },
    amountProfit: {
      color:
        theme.palette.type === "dark"
          ? theme.palette.success.light
          : theme.palette.success.dark,
    },
    amountRepay: {
      color:
        theme.palette.type === "dark"
          ? theme.palette.primary.light
          : theme.palette.primary.dark,
    },
  })
);

type OwnProps = {
  data: IData[];
  selected: IData[];
  toolbarDate: Date;

  selectAllRows: (event: React.ChangeEvent<HTMLInputElement>) => void;
  selectRow: (name: string) => void;
  removeSelectedRows: () => void;
  isSelected: (name: string) => boolean;
  setToolbarDate: Dispatch<SetStateAction<Date>>;
};

const DataDisplayTables = (props: OwnProps) => {
  const classes = useStyles();
  const [order, setOrder] = useState<Order>("desc");
  const [orderBy, setOrderBy] = useState<keyof IData>("dateOfAmount");
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(5);
  const { typeAccount } = useParams<IParamsAccount>();

  useEffect(() => {
    setPage(0);
  }, [props.toolbarDate, setPage, typeAccount]);

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof IData
  ): void => {
    const isAsc: boolean = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event: unknown, newPage: number): void => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const formaterDate = (dateString: string): string => {
    const date: Date = new Date(dateString);
    const newDate: string = new Intl.DateTimeFormat("fr-FR").format(date);

    return newDate;
  };

  // const isAmountDebit = (amount: number): boolean => {
  //   return amount < 0;
  // };

  const selectClassNameForAmount = (row: IData): string => {
    if (row.kindOfAmount === "Remboursements-Commun") {
      return classes.amountRepay;
    } else if (row.kindOfAmount !== "Remboursements-Commun" && row.amount < 0) {
      return classes.amountDebit;
    } else {
      return classes.amountProfit;
    }
  };

  const isRepay = (row: IData): number => {
    if (
      row.kindOfAmount === "Remboursements-Commun" &&
      row.whoPaid !== typeAccount &&
      typeAccount !== "common"
    ) {
      return -row.amount;
    }

    return row.amount;
  };

  const emptyRows: number =
    rowsPerPage - Math.min(rowsPerPage, props.data.length - page * rowsPerPage);

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <TableToolbar
          numSelected={props.selected.length}
          removeSelectedRows={props.removeSelectedRows}
          toolbarDate={props.toolbarDate}
          setToolbarDate={props.setToolbarDate}
          setPage={setPage}
        />
        <TableContainer className={classes.container}>
          <Table
            aria-labelledby="titreTableau"
            size={"small"}
            aria-label="Tableau"
            stickyHeader
          >
            <TableHeader
              numSelected={props.selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={props.selectAllRows}
              onRequestSort={handleRequestSort}
              rowCount={props.data.length}
            />
            <TableBody>
              {stableSort(props.data, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row: IData, index: number) => {
                  const isItemSelected = props.isSelected(row._id);
                  const labelId = `enhanced-table-checkbox-${index}`;
                  // const amount = isRepay(row);

                  return (
                    <TableRow
                      hover
                      onClick={() => props.selectRow(row._id)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row._id}
                      selected={isItemSelected}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={isItemSelected}
                          inputProps={{ "aria-labelledby": labelId }}
                        />
                      </TableCell>
                      <TableCell
                        align="left"
                        component="th"
                        id={labelId}
                        scope="row"
                        padding="none"
                      >
                        {formaterDate(row.dateOfAmount)}
                      </TableCell>
                      <TableCell align="left">{row.name}</TableCell>

                      <TableCell
                        align="left"
                        size="small"
                        className={selectClassNameForAmount(row)}
                      >
                        <span>{isRepay(row)}€</span>
                      </TableCell>
                      <TableCell
                        className={classes.notRenderingByBreakPoints}
                        align="left"
                      >
                        {typeAccount === "common"
                          ? row.whoPaid
                          : row.paymentMethod}
                      </TableCell>
                      <TableCell
                        className={classes.notRenderingByBreakPoints}
                        align="left"
                      >
                        {row.kindOfAmount}
                      </TableCell>
                      <TableCell
                        className={classes.notRenderingByBreakPoints}
                        align="left"
                      >
                        {typeAccount === "common"
                          ? row.isProrata
                          : row.isCommon}
                      </TableCell>
                      <TableCell
                        className={
                          typeAccount === "common"
                            ? classes.notRenderingCommon
                            : classes.notRenderingByBreakPoints
                        }
                        align="left"
                        size="small"
                      >
                        {row.isRealised}
                      </TableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 33 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={props.data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
};

export default DataDisplayTables;
